import styled from 'styled-components';

interface ButtonProps {
  variant?: 'outlined' | 'text';
  width?: string;
}

export const Button = styled.button<ButtonProps>`
  border-radius: 0;
  cursor: pointer;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.coral};
  border: 1px solid ${({ theme }) => theme.colors.coral};
  color: white;
  outline: none;

  ${({ width }) => (width ? `width: ${width};` : 'padding: 8px 25px;')}

  ${({ variant, theme }) => {
    switch (variant) {
      case 'text':
        return `
          background-color: white;
          border: 1px solid white;
          color: ${theme.colors.coral};
        `;
      case 'outlined':
        return `
          background-color: white;
          border: 1px solid ${theme.colors.coral};
          color: ${theme.colors.coral};
        `;
    }
  }}

  &:disabled {
    color: #c8cacd;
    border-color: gray;
    cursor: not-allowed;
  }

  @media print {
    visibility: hidden;
  }
`;
