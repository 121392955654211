import { useState } from 'react';

export const useShowImageActions = () => {
  const [showActions, setShowActions] = useState(false);

  return {
    showActions,
    setShowActions,
  };
};
