import React from 'react';
import styled from 'styled-components';
import { SectionIdentifier } from '../../types/TemplateSectionType';
import ImagePreview from '../ImagePreview';
import { Modal } from 'react-responsive-modal';
import { Button, SelectImageContent } from '../index';
import { TemplateSection } from '../../types/TemplateDescription';
import { useModal } from '../../hooks';
import { useTranslation } from 'react-i18next';
import {
  getPageState,
  updateImageAttributes,
} from '../../features/pages/pagesSlices';
import { useDispatch, useSelector } from 'react-redux';

interface ImagesTemplateProps {
  imageSection: TemplateSection | undefined;
}

const ImagesTemplate = ({ imageSection }: ImagesTemplateProps) => {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const { isHoverBody } = useSelector(getPageState);
  const imageAttributes = imageSection?.imageAttributes;
  const {
    isOpen: isSelectOpen,
    onCloseModal: onCloseSelectModal,
    onOpenModal: onOpenSelectModal,
  } = useModal();

  const imageAttributesLength = imageAttributes
    ? imageAttributes.filter(
        image =>
          image.value && image.attributeId !== SectionIdentifier.MainImage,
      ).length
    : 0;

  const onDeleteImage = (imageIndex: number) => {
    dispatch(
      updateImageAttributes({ imageIndex, data: '', replaceDefault: false }),
    );
  };

  const onSaveImageChanges = (
    imageIndex: number,
    data: string,
    replaceDefault: boolean,
    moveToEnd: boolean,
  ) => {
    dispatch(
      updateImageAttributes({ imageIndex, data, replaceDefault, moveToEnd }),
    );
  };

  const onSaveSelect = (data: string) => {
    const emptyImageAttributeIndex = imageAttributes
      ? imageAttributes.findIndex(
          image =>
            !image.value && image.attributeId !== SectionIdentifier.MainImage,
        )
      : 0;

    onSaveImageChanges(emptyImageAttributeIndex, data, true, true);
    onCloseSelectModal();
  };

  const aspectRatio =
    imageAttributesLength > 1 && imageAttributesLength < 3 ? 0.44 : 0.9;

  return (
    <ImagesWrapper>
      {imageAttributes?.map(
        (image, idx) =>
          (image.value || (idx === 1 && imageAttributesLength === 0)) &&
          image.attributeId !== SectionIdentifier.MainImage && (
            <CenterImageWrapper
              imageAttributesLength={imageAttributesLength}
              key={idx}
            >
              <ImagePreview
                aspectRatio={aspectRatio}
                imageData={image.value}
                defaultImageData={image.defaultValue}
                onDeleteImage={() => onDeleteImage(idx)}
                onImageSelect={data =>
                  onSaveImageChanges(idx, data, true, false)
                }
                onImageEdit={data =>
                  onSaveImageChanges(idx, data, false, false)
                }
                testId={`image-${idx}`}
              />
            </CenterImageWrapper>
          ),
      )}
      <AddImageButton
        variant="outlined"
        onClick={onOpenSelectModal}
        hide={
          !isHoverBody ||
          imageAttributesLength === 4 ||
          imageAttributesLength === 0
        }
        data-testid="add-image"
      >
        {t('general.addImage')}
      </AddImageButton>
      <Modal
        center
        open={isSelectOpen}
        onClose={onCloseSelectModal}
        styles={{ modal: { padding: '40px 80px' } }}
      >
        <SelectImageContent
          onClose={onCloseSelectModal}
          onSave={onSaveSelect}
        />
      </Modal>
    </ImagesWrapper>
  );
};

const ImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
`;

const CenterImageWrapper = styled.div<{
  imageAttributesLength: number;
}>`
  padding: 10px;
  height: ${props => (props.imageAttributesLength > 2 ? '50%' : '100%')};
  width: ${props => (props.imageAttributesLength > 1 ? '50%' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #f4f4f5;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const AddImageButton = styled(Button)<{ hide: boolean }>`
  position: absolute;
  right: 0;
  top: -3em;
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s;
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`;

export default ImagesTemplate;
