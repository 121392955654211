import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, LoadingOverlay } from 'components';
import { httpClient } from 'api/http-client';
import { useSearchParams } from 'react-router-dom';
import { ArrowDownIcon, ArrowUpIcon, LoadingIcon } from 'assets';
import { toBase64 } from 'helpers';
import { addImage, getSelectImageState, setImages } from './selectImageSlice';
import { useDispatch, useSelector } from 'react-redux';

interface ImageCropperPropsType {
  onClose: () => void;
  onSave: (data: string) => void;
}

const SelectImageContent = ({ onClose, onSave }: ImageCropperPropsType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const imageListItemRef = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const [selectedImage, setSelectedImage] = useState<number>(-1);
  const [isUploading, setUploading] = useState<boolean>(false);
  const { images, loaded } = useSelector(getSelectImageState);

  const ecmUrl = useMemo(
    () => new URL(searchParams.get('returnUrl') || ''),
    [searchParams],
  );
  const dealerId = useMemo(
    () => searchParams.get('dealerId') || '',
    [searchParams],
  );
  const productIds = useMemo(
    () => (searchParams.get('productId') || '').split(','),
    [searchParams],
  );

  useEffect(() => {
    const fetchProductImages = async () => {
      const images = await Promise.all(
        productIds.map(async productId => {
          return await httpClient.get(`product/${productId}/images`, {
            DealerId: dealerId,
            EcmUrl: ecmUrl.origin,
          });
        }),
      );
      dispatch(setImages(Array.from(new Set(images.flat()))));
    };
    if (!loaded) fetchProductImages();
  }, [dispatch, loaded, productIds, dealerId, ecmUrl.origin]);

  const onFileChange = async (e: any) => {
    const files = e.target.files;
    setUploading(true);
    if (files && files.length > 0) {
      const base64 = (await toBase64(files[0])) as string;
      dispatch(addImage(base64));
      setUploading(false);
      setSelectedImage(0);
    }
  };

  const onArrowClick = (step: number) => {
    setSelectedImage(state => state + step);
    imageListItemRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <div>
      {!loaded && <LoadingOverlay />}
      <Title>{t('general.selectImage')}</Title>
      <Description>{t('general.selectImageDescription')}</Description>
      <ImageContainer>
        <SelectedImage>
          {images[selectedImage] ? (
            <LargePreviewImage
              src={images[selectedImage]}
              alt="Product preview"
            />
          ) : (
            <AddImagePlaceholder>
              <AddImageInputLabel>
                <AddImagePlus>+</AddImagePlus>
                <AddImageText>Upload new</AddImageText>
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={onFileChange}
                  data-testid="upload-new"
                />
              </AddImageInputLabel>
            </AddImagePlaceholder>
          )}
        </SelectedImage>
        <ImagePreviewWrapper>
          <ArrownIconWrapper
            onClick={() => {
              onArrowClick(-1);
            }}
            isDisabled={selectedImage === -1}
            data-testid="arrow-up"
          >
            <ArrowUpIcon />
          </ArrownIconWrapper>

          <ImagePreviewList>
            <ImagePreviewItem
              onClick={() => setSelectedImage(-1)}
              data-testid="select-image"
              isSelected={selectedImage === -1}
            >
              <AddImagePlaceholder>
                {isUploading ? <LoadingIcon /> : <AddImagePlus>+</AddImagePlus>}
              </AddImagePlaceholder>
            </ImagePreviewItem>
            {isUploading && (
              <ImagePreviewItem>
                <AddImagePlaceholder>
                  <LoadingIcon />
                </AddImagePlaceholder>
              </ImagePreviewItem>
            )}
            {images.map((image, idx) => {
              return (
                <ImagePreviewItem
                  key={idx}
                  onClick={() => setSelectedImage(idx)}
                  data-testid="preview-image"
                  isSelected={idx === selectedImage}
                  ref={idx === selectedImage ? imageListItemRef : null}
                >
                  <PreviewImage src={image} alt="Product preview" />
                </ImagePreviewItem>
              );
            })}
          </ImagePreviewList>

          <ArrownIconWrapper
            isDisabled={selectedImage === images.length - 1}
            onClick={() => {
              onArrowClick(1);
            }}
            data-testid="arrow-down"
          >
            <ArrowDownIcon />
          </ArrownIconWrapper>
        </ImagePreviewWrapper>
      </ImageContainer>
      <AppButtons>
        <Button
          width="50%"
          variant="text"
          onClick={onClose}
          data-testid="cancel"
        >
          {t('general.cancel')}
        </Button>
        <Button
          width="50%"
          onClick={() => onSave(images[selectedImage])}
          data-testid="save"
        >
          {t('general.save')}
        </Button>
      </AppButtons>
    </div>
  );
};

const Title = styled.h1`
  font-size: 30px;
  font-weight: 800;
  height: 36px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: left;
`;

const Description = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  height: 23px;
`;

const ImageContainer = styled.div`
  margin-top: 20px;
  padding-left: 10px;
  width: 100%;
  display: flex;
  height: 380px;
`;

const SelectedImage = styled.div`
  width: 380px;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
`;

const ImagePreviewWrapper = styled.div`
  margin-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const ImagePreviewList = styled.div`
  display: flex;
  overflow: scroll;
  flex-direction: column;
  flex-grow: 1;
`;

const ImagePreviewItem = styled.div<{
  isSelected?: boolean;
}>`
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f4f4f4;
  border: ${props =>
    props.isSelected ? '2px solid #F47457' : '2px solid #FFF'};
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  align-self: center;
  object-fit: cover;
`;

const LargePreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  align-self: center;
`;

const AddImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #f47457;
`;

const AddImageInputLabel = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    display: none;
  }
`;

const AddImagePlus = styled.div`
  font-size: 3rem;
  margin: 0;
  padding: 0;
`;

const AddImageText = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
`;

const ArrownIconWrapper = styled.div<{
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  svg {
    path {
      fill: ${props => (props.isDisabled ? '#c8cacd' : '#363636')};
    }
  }
`;

const AppButtons = styled.div`
  margin-top: 40px;
  margin-left: auto;
  position: relative;
  width: 280px;
`;

export default SelectImageContent;
