import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { checkImage, closeImage, edit2Image } from 'assets';

interface FormInputProps {
  label: string;
  inputValue: string;
  placeHolder?: string;
  disabled: boolean;
  isMultiLine: boolean;
  isLabelEditable: boolean;
  isLabelAlwaysShow?: boolean;
  onChange: (value: string) => void;
  onLabelChange: (value: string) => void;
  textMaxLength?: number;
  id?: string;
  name?: string;
}

type WrapperProps = Omit<FormInputProps, 'onChange' | 'onLabelChange'>;

const FormInput = (props: FormInputProps) => {
  const labelWrapperRef = useRef<HTMLSpanElement>(null);

  const [isLabelEditing, setIsLabelEditing] = useState(false);
  const [tmpLabelText, setTmpLabelText] = useState('');

  const onApplyLabelClick = useCallback(() => {
    setIsLabelEditing(true);
    props.onLabelChange(tmpLabelText);
  }, [props, tmpLabelText]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        labelWrapperRef.current != null &&
        !labelWrapperRef.current!!.contains(event.target as Node)
      ) {
        onApplyLabelClick();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onApplyLabelClick, tmpLabelText]);

  const wrapperProps: WrapperProps = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onChange, onLabelChange, id, name, ...wrapperProps } = props;

    return wrapperProps;
  }, [props]);

  return (
    <FormInputWrap {...wrapperProps} data-testid="form-input">
      <FormInputWrapSecond>
        {!isLabelEditing ? (
          <FormInputLabel htmlFor={props.id}>
            {props.label}
            {props.isLabelEditable && (
              <FormEditableIcon
                id="input-label-edit-btn"
                src={edit2Image}
                alt=""
                onClick={() => {
                  setTmpLabelText(props.label);
                  setIsLabelEditing(true);
                }}
              />
            )}
          </FormInputLabel>
        ) : (
          <span ref={labelWrapperRef}>
            <FormEditableInput
              value={tmpLabelText}
              onChange={e => {
                const element = e.target as HTMLInputElement;
                setTmpLabelText(element.value);
              }}
            />
            <FormEditableOk
              src={checkImage}
              alt=""
              onClick={onApplyLabelClick}
            />
            <FormEditableClose
              src={closeImage}
              alt=""
              onClick={() => setIsLabelEditing(false)}
            />
          </span>
        )}
        {props.isMultiLine ? (
          <FormTextarea
            textMaxLength={props.textMaxLength}
            inputValue={props.inputValue}
            rows={5}
            cols={50}
            disabled={props.disabled}
            value={props.inputValue.replace('<br/>', '\n')}
            onChange={e => {
              const element = e.target as HTMLTextAreaElement;
              props.onChange(element.value);
            }}
            maxLength={props.textMaxLength}
          />
        ) : (
          <FormInputText
            id={props.id}
            name={props.id}
            textMaxLength={props.textMaxLength}
            inputValue={props.inputValue}
            placeholder={props.placeHolder}
            disabled={props.disabled}
            value={props.inputValue}
            onChange={e => {
              const element = e.target as HTMLInputElement;
              props.onChange(element.value);
            }}
            maxLength={props.textMaxLength}
            data-testid="input-text"
          />
        )}
        {props.textMaxLength != null && (
          <FormMaxLength
            id="textMaxLengthCountdown"
            textMaxLength={props.textMaxLength}
            inputValue={props.inputValue}
          >
            {props.inputValue.length} / {props.textMaxLength}
          </FormMaxLength>
        )}
      </FormInputWrapSecond>
    </FormInputWrap>
  );
};

export default FormInput;

const FormInputWrap = styled.div<WrapperProps>`
  height: ${props => (props.isMultiLine ? '156px' : '44px')};
  border-radius: 5px;
  padding-bottom: 80px;
  position: relative;
  user-select: none;
`;

const FormInputWrapSecond = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const FormInputLabel = styled.label`
  font-size: 0.875rem;
  margin-bottom: 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FormEditableIcon = styled.img`
  color: gray;
  width: 10px;
  height: 10px;
  padding-left: 30px;
  float: right;
  display: none;
`;

const FormEditableInput = styled.input`
  border-bottom: 1px solid #f46457;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  min-height: 16px;
  letter-spacing: 0.6px;
  width: 80%;
`;

const FormEditableOk = styled.img`
  color: gray;
  width: 5%;
  height: 5%;
  padding-left: 10px;
  float: right;
`;

const FormEditableClose = styled.img`
  color: gray;
  width: 6%;
  height: 6%;
  float: right;
`;

const inputStyles = `
  border: 1px solid #c8cacd;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  outline-color: #f8f7f7;
  height: 40px;
  &:active {
    color: rgba(63, 63, 70, 1);
    border: 1px solid #c8cacd;
    border-radius: 4px;
  }
  &:focus {
    outline-color: #f8f7f7;
      & ~ #textMaxLengthCountdown {
        display: inherit;
      }
  }
  &:disabled {
    color: darkgray;
  }
`;

type InputProps = {
  textMaxLength?: number;
  inputValue: string;
};

const FormTextarea = styled.textarea<InputProps>`
  ${inputStyles};
  resize: none;
  max-width: 280px;
  width: 100%;
  ${props =>
    props.textMaxLength != null &&
    props.inputValue.length > props.textMaxLength &&
    `
      border-color: red;
    `}
`;

const FormInputText = styled.input<InputProps>`
  ${inputStyles};
  font-size: 14px;
  ${props =>
    props.textMaxLength != null &&
    props.inputValue.length > props.textMaxLength &&
    `
      border-color: red;
    `}
`;

const FormMaxLength = styled.span<InputProps>`
  display: none;
  margin-top: 5px;
  font-size: 12px;
  ${props =>
    props.inputValue.length > props.textMaxLength!! &&
    `
      color: red;
    `}
`;
