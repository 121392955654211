import { configureStore } from '@reduxjs/toolkit';
import loadingReducer, { loadingSlice } from 'features/loading/loadingSlice';
import modalsReducer, { modalsSlice } from 'features/modals/modalsSlice';
import pagesReducer, { pagesSlice } from 'features/pages/pagesSlices';
import templateSettingsReducer, {
  templateSettingsSlice,
} from 'features/settings/templateSettingsSlice';
import companyDetailsReducer, {
  companyDetailsSlice,
} from 'features/companyDetails/companyDetailsSlice';
import notificationReducer, {
  notificationSlice,
} from '../features/notification/notificationSlice';
import selectImageReducer, {
  selectImageSlice,
} from '../features/selectImage/selectImageSlice';

export const store = configureStore({
  reducer: {
    [modalsSlice.name]: modalsReducer,
    [templateSettingsSlice.name]: templateSettingsReducer,
    [companyDetailsSlice.name]: companyDetailsReducer,
    [loadingSlice.name]: loadingReducer,
    [pagesSlice.name]: pagesReducer,
    [notificationSlice.name]: notificationReducer,
    [selectImageSlice.name]: selectImageReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
