import styled from 'styled-components';
import { TypoBase } from 'components';

const TextBig1 = styled(TypoBase)`
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
`;

const TextBig2 = styled(TypoBase)`
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
`;

const TextRegular1 = styled(TypoBase)`
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
`;

const TextRegular2 = styled(TypoBase)`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;

const TextSmall = styled(TypoBase)`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;

const TextButton = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #f47457;
`;

export {
  TextBig1,
  TextBig2,
  TextRegular1,
  TextRegular2,
  TextSmall,
  TextButton,
};
