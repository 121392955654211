import {
  Dealer,
  updateCompanyDetails,
} from 'features/companyDetails/companyDetailsSlice';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { ToggleInputField } from '../../components/ToggleInputField';
import { addNotification } from '../notification/notificationSlice';
import { useTranslation } from 'react-i18next';

export interface CompanyInfoProps {
  dealer: Dealer;
}

export function CompanyInfo({ dealer }: CompanyInfoProps) {
  const { t } = useTranslation();
  const formattedAddress = dealer.company_address?.formattedAddress;
  const [companyInfo, setCompanyInfo] = useState({
    companyName: dealer.company_name,
    companyAddress: formattedAddress,
    companyUrl: dealer.company_url,
    phoneNumber: dealer.phone_number,
  });

  useEffect(() => {
    setCompanyInfo({
      companyName: dealer.company_name,
      companyAddress: formattedAddress,
      companyUrl: dealer.company_url,
      phoneNumber: dealer.phone_number,
    });
  }, [dealer, formattedAddress]);

  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyInfo(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleUpdate = () => {
    if (
      companyInfo.companyName !== dealer.company_name ||
      companyInfo.companyAddress !== formattedAddress ||
      companyInfo.companyUrl !== dealer.company_url ||
      companyInfo.phoneNumber !== dealer.phone_number
    ) {
      dispatch(updateCompanyDetails(companyInfo));
      dispatch(addNotification({ message: 'Company details updated' }));
    }
  };

  const getCompanyUrl = () => {
    if (dealer.company_url && !/^https?:\/\//i.test(dealer.company_url)) {
      return 'http://' + dealer.company_url;
    }
    return dealer.company_url;
  };

  return (
    <address>
      <ToggleInputField
        name="companyName"
        placeholder={t('placeholder.companyName')}
        value={companyInfo.companyName}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
      >
        <Paragraph>{companyInfo.companyName}</Paragraph>
      </ToggleInputField>

      <ToggleInputField
        name="companyAddress"
        placeholder={t('placeholder.companyAddress')}
        value={companyInfo.companyAddress}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
      >
        <Paragraph>{companyInfo.companyAddress}</Paragraph>
      </ToggleInputField>
      <ToggleInputField
        name="phoneNumber"
        placeholder={t('placeholder.phoneNumber')}
        value={companyInfo.phoneNumber}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
      >
        <Paragraph>{companyInfo.phoneNumber}</Paragraph>
      </ToggleInputField>

      <ToggleInputField
        name="companyUrl"
        placeholder="Company Website"
        value={companyInfo.companyUrl}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
      >
        <CompanyWebsiteLinkWeb>{dealer.company_url}</CompanyWebsiteLinkWeb>
        <CompanyWebsiteLinkPDF href={getCompanyUrl()} target="blank">
          {dealer.company_url}
        </CompanyWebsiteLinkPDF>
      </ToggleInputField>
    </address>
  );
}

const commonStyle = css`
  font-style: normal;
  font-size: 1em;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Paragraph = styled.p`
  ${commonStyle};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const CompanyWebsiteLinkWeb = styled.p`
  ${commonStyle};
  display: block;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
  max-width: 90%;
  @media print {
    display: none;
  }
`;

const CompanyWebsiteLinkPDF = styled.a`
  ${commonStyle};
  display: none;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
  max-width: 90%;
  @media print {
    display: block;
  }
`;
