import React, { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  RotateLeftIcon,
  RotateRightIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'assets';
import { Button } from 'components';

interface ImageCropperPropsType {
  imageData: string;
  defaultImageData: string;
  onClose: () => void;
  onSave: (data: string) => void;
  aspectRatio?: number;
}

const EditImageContent = ({
  imageData,
  defaultImageData,
  onClose,
  onSave,
  aspectRatio = 1.4,
}: ImageCropperPropsType) => {
  const { t } = useTranslation();
  const [cropper, setCropper] = useState<Cropper | null>();
  const [cropData, setCropData] = useState(imageData);

  const getCropData = () => {
    const cropData = cropper?.getCroppedCanvas().toDataURL();
    if (!cropData) {
      return;
    }
    if (typeof cropper !== 'undefined') {
      setCropData(cropData);
    }

    onSave(cropData);
  };

  return (
    <div data-testid="image-cropper">
      <Title>{t('general.editImage')}</Title>
      <Description>{t('general.editImageDescription')}</Description>
      <CropperWrapper>
        <Cropper
          checkCrossOrigin={false}
          src={cropData}
          style={{
            height: '320px',
            width: '480px',
          }}
          aspectRatio={aspectRatio}
          guides={false}
          movable={false}
          responsive={true}
          autoCropArea={1}
          background={false}
          center={true}
          checkOrientation={false}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          viewMode={0}
          zoomOnWheel={true}
          zoomOnTouch={false}
          onInitialized={instance => {
            setCropper(instance);
          }}
          crop={() => {
            setTimeout(() => {
              let canvas_img =
                window.document.querySelector('img.cropper-hide');
              let src = canvas_img?.getAttribute('src') || '';
              canvas_img?.setAttribute('crossorigin', 'anonymous');
              canvas_img?.setAttribute('src', src);
            }, 100);
          }}
        />
      </CropperWrapper>
      <ButtonUtilitiesWrapper>
        <ButtonsContainer>
          <ButtonGroup isMarginLeft={true}>
            <ZoomInIcon onClick={() => cropper?.zoom(0.1)} />
          </ButtonGroup>
          <ButtonGroup isMarginLeft={false}>
            <ZoomOutIcon onClick={() => cropper?.zoom(-0.1)} />
          </ButtonGroup>
        </ButtonsContainer>
        <VerticalLine />
        <ResetButton
          onClick={() => {
            cropper?.replace(defaultImageData);
          }}
        >
          {t('general.reset')}
        </ResetButton>
        <VerticalLine />
        <ButtonsContainer>
          <ButtonGroup isMarginLeft={true}>
            <RotateLeftIcon onClick={() => cropper?.rotate(-45)} />
          </ButtonGroup>
          <ButtonGroup isMarginLeft={false}>
            <RotateRightIcon onClick={() => cropper?.rotate(45)} />
          </ButtonGroup>
        </ButtonsContainer>
      </ButtonUtilitiesWrapper>
      <AppButtons>
        <Button width="50%" variant="text" onClick={onClose}>
          {t('general.cancel')}
        </Button>
        <Button width="50%" onClick={getCropData}>
          {t('general.save')}
        </Button>
      </AppButtons>
    </div>
  );
};

const CropperWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 3px solid #9c9c9c;
  background-color: #9c9c9c;
`;

const Title = styled.h1`
  font-size: 30px;
  font-weight: 800;
  height: 36px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: left;
`;

const Description = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  height: 23px;
`;

const ButtonUtilitiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 54px;
  justify-content: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  position: relative;
  width: 333px;
  background-color: #f4f4f4;
  border-radius: 25px;
`;

const ButtonsContainer = styled.div`
  text-align: center;
  position: relative;
  width: 118px;
  height: 100%;
  margin-top: 17px;
  user-select: none;
`;

const ButtonGroup = styled.div<{ isMarginLeft: boolean }>`
  display: inline-block;
  margin-left: ${props => (props.isMarginLeft ? '20%' : 'auto')};
  margin-right: ${props => (!props.isMarginLeft ? '20%' : 'auto')};
  min-height: 20px;
  min-width: 20px;
  width: 30%;
  svg {
    margin: 0 auto;
    color: gray;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  background-color: #e3e3e3;
  height: 34px;
  align-items: center;
  margin: 10px auto;
  left: 50%;
`;

const ResetButton = styled.div`
  align-items: center;
  color: #f47457;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  height: 100%;
  margin: 18.5px auto;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 97px;
  user-select: none;
`;

const AppButtons = styled.div`
  margin: 40px auto 0 auto;
  text-align: center;
  position: relative;
  width: 280px;
`;

export default EditImageContent;
