import {
  TemplateDescription,
  TemplateSectionTab,
} from 'types/TemplateDescription';
export const ATTRIBUTE_TEXT_MAX_LENGTH = 20;

export const template1Description: TemplateDescription = {
  productId: '',
  companyDetails: {
    showInfo: true,
    showLogo: true,
  },
  productThumbnail: '',
  sections: [
    {
      imageAttributes: [],
      maxAttributesAllowed: 1,
      sectionId: 'brochure-title',
      sectionTab: TemplateSectionTab.Details,
      textAttributes: [
        {
          attributeId: 'title',
          attributeLabel: 'TITLE',
          enabled: true,
          isLabelEditable: false,
          isMultiLine: false,
          order: 1,
          textMaxLength: 186,
          value: '',
          dimension: null,
        },
      ],
    },
    {
      imageAttributes: [
        {
          aspectRatio: 1.097,
          attributeId: 'image',
          defaultValue: '',
          enabled: true,
          order: 1,
          value: '',
        },
      ],
      maxAttributesAllowed: 1,
      sectionId: 'main-image',
      sectionTab: TemplateSectionTab.Images,
      textAttributes: [],
    },
    {
      imageAttributes: [],
      maxAttributesAllowed: 1,
      sectionId: 'description',
      sectionTab: TemplateSectionTab.Details,
      textAttributes: [
        {
          attributeId: 'description',
          attributeLabel: 'DESCRIPTION',
          enabled: true,
          isLabelEditable: false,
          isMultiLine: true,
          order: 1,
          textMaxLength: 600,
          value: '',
          dimension: null,
        },
      ],
    },
    {
      imageAttributes: [],
      maxAttributesAllowed: 9,
      sectionId: 'attributesVerticalList',
      sectionTab: TemplateSectionTab.Details,
      textAttributes: [
        {
          attributeId: 'price',
          attributeLabel: 'PRICE',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 1,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'condition',
          attributeLabel: 'CONDITION',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 2,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'year-produced',
          attributeLabel: 'YEAR PRODUCED',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 3,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'origin',
          attributeLabel: 'ORIGIN',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 4,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'primary-color',
          attributeLabel: 'PRIMARY COLOR',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 5,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'height',
          attributeLabel: 'HEIGHT',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 6,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'width',
          attributeLabel: 'WIDTH',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 7,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
        {
          attributeId: 'depth',
          attributeLabel: 'DEPTH',
          enabled: true,
          isLabelEditable: true,
          isMultiLine: false,
          order: 8,
          textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
          value: '',
          dimension: null,
        },
      ],
    },
  ],
  templateId: 'product-page-1',
};
