import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  ButtonInverse,
  DownloadPdfConfirmModal,
  TemplateConfirmationModal,
  TextBig2,
  TextButton,
} from 'components';
import { useConfirmResetAllChange, useModal } from 'hooks';
import { BackArrow, DownloadIcon, UndoIcon } from '../assets';
import {
  getPageUndoableStatus,
  redo,
  undo,
} from '../features/pages/pagesSlices';

interface HeaderProps {
  onDownloadPdfButtonClick: () => void;
  onBackToInventoryClick: () => void;
  onChangeTemplate: () => void;
  isMobileView: boolean;
}

const Header = ({
  onBackToInventoryClick,
  onDownloadPdfButtonClick,
  onChangeTemplate,
  isMobileView,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { isOpen, onCloseModal, onOpenModal } = useModal();
  const dispatch = useDispatch();
  const { canUndo, canRedo } = useSelector(getPageUndoableStatus);
  const {
    showConfirmModal,
    handleCloseConfirmModal,
    handleConfirmReset,
    handleResetAllChange,
  } = useConfirmResetAllChange();

  const onUndo = () => {
    if (canUndo) {
      dispatch(undo());
    }
  };

  const onRedo = () => {
    if (canRedo) {
      dispatch(redo());
    }
  };

  return (
    <HeaderContainer data-testid="header">
      <NavBar>
        <FirstNavBarSection
          isMobileView={isMobileView}
          onClick={onBackToInventoryClick}
        >
          <LogoImage src="/logo.png" alt="logo" />
          <ButtonBackToInventory
            data-testid="back-to-inventory"
            variant="outlined"
            onClick={onBackToInventoryClick}
          >
            <BackArrow />
            <span>{t('header.backToInventory')}</span>
          </ButtonBackToInventory>
        </FirstNavBarSection>
        <ResetAllChangeSection>
          <UndoRedoWrapper
            onClick={onUndo}
            disabled={!canUndo}
            data-testid="undo"
          >
            <UndoIcon width="20px" color="red" />
            <p>{t('header.undo')}</p>
          </UndoRedoWrapper>
          <ResetAllChangeText
            onClick={handleResetAllChange}
            data-testid="reset-all"
          >
            {t('header.resetAllChanges')}
          </ResetAllChangeText>
          <UndoRedoWrapper
            onClick={onRedo}
            disabled={!canRedo}
            data-testid="redo"
          >
            <p>{t('header.redo')}</p>
            <RedoIconWrapper>
              <UndoIcon width="20px" />
            </RedoIconWrapper>
          </UndoRedoWrapper>
        </ResetAllChangeSection>
        <ThirdNavBarSection>
          <ButtonChangeTemplate
            onClick={onChangeTemplate}
            data-testid="change-template"
          >
            {t('general.changeTemplate')}
          </ButtonChangeTemplate>
          <ButtonDownload
            onClick={onOpenModal}
            data-testid="download-presentation"
          >
            <DownloadIcon />
            {t('header.downloadPresentation')}
          </ButtonDownload>
        </ThirdNavBarSection>
        <DownloadPdfConfirmModal
          isOpen={isOpen}
          onCloseModal={onCloseModal}
          onDownload={() => {
            onCloseModal();
            onDownloadPdfButtonClick();
          }}
        />
        <TemplateConfirmationModal
          title={t('modal.resetAllChanges.title')}
          isOpen={showConfirmModal}
          confirmationButtonText="Reset"
          onCloseModal={handleCloseConfirmModal}
          onConfirm={handleConfirmReset}
        >
          <TextBig2>{t('modal.resetAllChanges.content')}</TextBig2>
        </TemplateConfirmationModal>
      </NavBar>
    </HeaderContainer>
  );
};

export default Header;

export const HeaderContainer = styled.div`
  max-width: 1920px;
  max-height: 60px;
  margin: 0 auto;
  padding-top: 0;
  background-color: white;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  @media print {
    display: none;
  }
`;

export const NavBar = styled.nav`
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
`;

export const FirstNavBarSection = styled.div<{
  isMobileView: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-width: ${props => (props.isMobileView ? '345px' : '407px')};
`;

export const LogoImage = styled.img`
  cursor: pointer;
  max-width: 120px;
  min-width: 100px;
`;

export const ButtonBackToInventory = styled(Button)`
  font-size: 0.75rem;
  cursor: pointer;
  border-color: #3b506b;
  letter-spacing: 0.0125em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #3b506b;
`;

const ResetAllChangeSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const UndoRedoWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
  color: ${props => (props.disabled ? '#c8cacd' : undefined)};
  & svg {
    fill: ${props => (props.disabled ? '#c8cacd' : undefined)};
  }
  &:hover {
    color: ${props => (!props.disabled ? props.theme.colors.coral : undefined)};
    & svg {
      fill: ${props =>
        !props.disabled ? props.theme.colors.coral : undefined};
    }
  }
`;

const RedoIconWrapper = styled.div`
  transform: rotateY(180deg);
`;

const ThirdNavBarSection = styled.div`
  display: flex;
`;

const ButtonChangeTemplate = styled(ButtonInverse)`
  padding-left: 18px;
  white-space: nowrap;
  width: 152px;
`;

const ButtonDownload = styled(Button)`
  margin: 0 16px;
  width: 208px;
  letter-spacing: 0.0125em;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.colors.coral};
    & svg {
      fill: ${({ theme }) => theme.colors.coral};
    }
  }
`;

const ResetAllChangeText = styled(TextButton)`
  cursor: pointer;
  text-transform: uppercase;
`;
