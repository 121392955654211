import styled from 'styled-components';

export const ButtonInverse = styled.button`
  background-color: white;
  border-radius: 0;
  border: 1px solid white;
  color: #f47457;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 16px;
  padding: 11.5px 7.5px;
  text-align: left;
  text-transform: uppercase;
`;

export const ButtonBase = styled.button`
  background-color: #f47457;
  border-radius: 0;
  border: 1px solid #f47457;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 40px;
  line-height: 16px;
  padding: 11.5px 7.5px;
  text-align: center;
  text-transform: uppercase;
`;
