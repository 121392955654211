import React from 'react';
import styled from 'styled-components';
import { FormInput, ToggleButton } from 'components';

interface FormInputToggleProps {
  toggleValue: boolean;
  onToggleChange: (value: boolean) => void;
  inputLabel: string;
  inputValue: string;
  inputOnChange: (value: string) => void;
  onLabelChange: (value: string) => void;
  isMultiLine: boolean;
  isLabelEditable: boolean;
  textMaxLength: number;
}

const FormInputToggle = ({
  toggleValue,
  onToggleChange,
  inputLabel,
  inputValue,
  inputOnChange,
  onLabelChange,
  isMultiLine,
  isLabelEditable,
  textMaxLength,
}: FormInputToggleProps) => {
  return (
    <FormInputToggleWrap data-testid="form-input-toggle">
      <FormInputToggleButtonWrap>
        <ToggleButton toggleValue={toggleValue} onChange={onToggleChange} />
      </FormInputToggleButtonWrap>
      <FormInputWrap>
        <FormInput
          label={inputLabel}
          inputValue={inputValue}
          onChange={inputOnChange}
          onLabelChange={onLabelChange}
          disabled={!toggleValue}
          isMultiLine={isMultiLine}
          isLabelEditable={isLabelEditable}
          textMaxLength={textMaxLength}
        />
      </FormInputWrap>
    </FormInputToggleWrap>
  );
};

export default FormInputToggle;

const FormInputToggleWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 360px;
`;

const FormInputToggleButtonWrap = styled.div`
  margin-left: 5px;
  margin-top: 30px;
`;

const FormInputWrap = styled.div`
  max-width: 280px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
`;
