import React, { useRef } from 'react';
import styled from 'styled-components';
import { useClickOutside } from 'hooks';

interface PopoverProps {
  children: React.ReactNode;
  shown: boolean;
  onClose: () => void;
}

const ButtonCommon = ({ shown, children, onClose }: PopoverProps) => {
  const removeFieldPopoverRef = useRef<HTMLDivElement>(null);
  useClickOutside(removeFieldPopoverRef, onClose);
  if (shown) {
    return (
      <Popover ref={removeFieldPopoverRef} data-testid="popover">
        {children}
      </Popover>
    );
  }
  return null;
};

const Popover = styled.div`
  position: absolute;
  right: 15px;
  top: 45px;
  z-index: 10;
  background: #ffffff;
  padding: 20px;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
  width: 250px;
`;

export default ButtonCommon;
