import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { CloseIcon, PageIcon, RemoveIcon, ViewPageIcon } from 'assets';
import { H4, TemplateConfirmationModal, TextBig2 } from 'components/index';
import {
  changePage,
  getPageState,
  removePresent,
} from 'features/pages/pagesSlices';
import { useClickOutside, useModal } from 'hooks';
import { SectionIdentifier } from 'types/TemplateSectionType';
import ResizableSideBar from './ResizableSideBar';

interface LeftSidebarProps {
  isMobileView: boolean;
}

const LeftSidebar = ({ isMobileView }: LeftSidebarProps) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [isSideBarOpen, setIsSideBarOpen] = useState(!isMobileView);
  const [activeRemoveIcon, setActiveRemoveIcon] = useState<number | null>();
  const { isOpen: isModalOpen, onCloseModal, onOpenModal } = useModal();
  const [removingPage, setRemovingPage] = useState<number | undefined>();
  const closeSideBar = useCallback(() => {
    if (isMobileView) setIsSideBarOpen(false);
  }, [isMobileView]);
  useClickOutside(ref, closeSideBar);

  const { currentPageIndex, present, fetchingPages } =
    useSelector(getPageState);
  const dispatch = useDispatch();

  const onPageClick = (page: number, type: string) => {
    if (type === 'remove') {
      setRemovingPage(page);
      onOpenModal();
    } else {
      dispatch(changePage(page));
    }
  };

  const getTitle = (pageIdx: number) => {
    const page = present[pageIdx];
    const titleSection =
      page && page.sections.length > 0
        ? page.sections.find(
            s => s.sectionId === SectionIdentifier.BrochureTitle,
          )
        : null;

    return titleSection ? titleSection!.textAttributes[0].value : '';
  };

  const getPageRole = (templateId: string) => {
    const roles: Record<string, string> = {
      'product-page-1': t('general.attributes'),
      'product-page-2': t('general.summary'),
      'product-page-3': t('general.images'),
    };
    return roles[templateId];
  };

  const renderIcon = (pageIdx: number) => {
    if (pageIdx === currentPageIndex) {
      const isRemove = pageIdx === activeRemoveIcon && present.length >= 2;
      return (
        <ActionIconWrapper
          onClick={(e: any) => {
            e.stopPropagation();
            onPageClick(pageIdx, isRemove ? 'remove' : 'view');
          }}
          data-testid="action-icon"
        >
          {isRemove ? <RemoveIcon /> : <ViewPageIcon />}
        </ActionIconWrapper>
      );
    }
    return (
      <ActionIconWrapper
        style={{ display: activeRemoveIcon === pageIdx ? 'block' : 'none' }}
        onClick={(e: any) => {
          e.stopPropagation();
          onPageClick(pageIdx, 'remove');
        }}
        data-testid="action-icon"
      >
        <RemoveIcon />
      </ActionIconWrapper>
    );
  };

  const onRemovePage = () => {
    if (removingPage === undefined) {
      return;
    }

    dispatch(removePresent(removingPage!));
    onCloseModal();
  };

  return (
    <div ref={ref}>
      <ResizableSideBar
        direction="left"
        isOpen={isSideBarOpen}
        isMobileView={isMobileView}
        setOpen={setIsSideBarOpen}
        buttonLabel={`${t('general.page')} ${currentPageIndex + 1}/${
          present.length || 1
        }`}
      >
        <LeftSidebarContainer>
          <LeftSidebarUl>
            <LeftSidebarLi>
              <CloseIcon
                onClick={() => {
                  setIsSideBarOpen(false);
                }}
                data-testid="close-sidebar"
              />
              <H4>{t('general.pageNavigation')}</H4>
            </LeftSidebarLi>
            <LeftSidebarPageLi>
              {t('general.page')} {currentPageIndex + 1}/{present.length || 1}
            </LeftSidebarPageLi>
            {present.map((page, idx) => {
              return (
                <PageItemLi
                  key={idx}
                  onClick={() => onPageClick(idx, 'view')}
                  onMouseEnter={() => setActiveRemoveIcon(idx)}
                  onMouseLeave={() => setActiveRemoveIcon(null)}
                  data-testid="page-link"
                >
                  <PageParagraph lastPage={idx === present.length - 1}>
                    <ThumbnailContainer>
                      {page.productThumbnail ? (
                        <ThumbnailImage
                          src={page.productThumbnail}
                          alt="product-image"
                        />
                      ) : (
                        <PageIcon style={{ float: 'left' }} />
                      )}
                    </ThumbnailContainer>
                    <PageParagraphContent>
                      <span>{getTitle(idx)}</span>
                      <span>{getPageRole(page.templateId)}</span>
                    </PageParagraphContent>
                    {renderIcon(idx)}
                  </PageParagraph>
                </PageItemLi>
              );
            })}
            <PageLineBreak />
            {fetchingPages > 0 && (
              <LoadingWrapper>{t('general.loadingProducts')}</LoadingWrapper>
            )}
          </LeftSidebarUl>
          <TemplateConfirmationModal
            isOpen={isModalOpen}
            title={t('modal.removePage.title')}
            confirmationButtonText={t('general.remove')}
            onCloseModal={onCloseModal}
            onConfirm={onRemovePage}
          >
            <TextBig2>{t('modal.removePage.content')}</TextBig2>
          </TemplateConfirmationModal>
        </LeftSidebarContainer>
      </ResizableSideBar>
    </div>
  );
};

export const LeftSidebarContainer = styled.div`
  box-shadow: 1px 0 0 #dadada;
  min-height: calc(100vh - 60px);
  height: 100%;
  margin-top: 60px;
  background-color: #ffffff;
  @media print {
    display: none;
  }
`;

export const LeftSidebarUl = styled.ul`
  position: relative;
  overflow: hidden;
  padding: 0;
  list-style-type: none;
`;

export const LeftSidebarLi = styled.li`
  text-transform: uppercase;
  letter-spacing: 0.03em;
  padding: 20px 30px 5px;
  display: block;
  & > svg {
    margin-top: 20px;
    float: right;
    cursor: pointer;
  }
`;

export const LeftSidebarPageLi = styled.li`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 0.75px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.darkGrey};
  padding: 0 30px 20px;
  border-bottom: 1px solid rgb(221, 221, 221);
`;

const PageItemLi = styled.li`
  height: 60px;
  display: block;
  background: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  padding: 0 30px 0 40px;
`;

const PageParagraph = styled.div<{ lastPage: boolean }>`
  border-left-color: rgb(218, 218, 218);
  color: rgb(102, 102, 102);
  margin: 0;
  border-bottom: ${props =>
    props.lastPage ? 'none' : '1px solid rgb(221, 221, 221)'};
  display: flex;
  align-items: center;
  height: 60px;
  gap: 10px;
`;

const PageParagraphContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 80%;
  span {
    &:first-child {
      font-weight: 400;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:last-child {
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

const PageLineBreak = styled.div`
  border-bottom: 1px solid rgb(221, 221, 221);
`;

const LoadingWrapper = styled.div`
  color: #757575;
  font-size: 14px;
  font-weight: 800;
  padding: 15px 30px 15px 40px;
  display: inline-block;
`;

const ActionIconWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 1rem;
  height: 1rem;
`;

const ThumbnailContainer = styled.div`
  width: 35px;
  height: 35px;
  float: left;
`;

const ThumbnailImage = styled.img`
  max-width: 35px;
  max-height: 35px;
`;

export default LeftSidebar;
