import ImagePreview from 'components/ImagePreview';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { TemplateSection } from 'types/TemplateDescription';
import { SectionIdentifier } from 'types/TemplateSectionType';
import { updateImageAttributes } from '../features/pages/pagesSlices';

interface SectionImageListProps {
  imageSection: TemplateSection | undefined;
}

const SectionImageList = ({ imageSection }: SectionImageListProps) => {
  const imageAttributes = imageSection?.imageAttributes;
  const dispatch = useDispatch();

  const onDeleteImage = (imageIndex: number) => {
    dispatch(
      updateImageAttributes({ imageIndex, data: '', replaceDefault: false }),
    );
  };

  const onSaveImageChanges = (
    imageIndex: number,
    data: string,
    replaceDefault: boolean,
  ) => {
    dispatch(updateImageAttributes({ imageIndex, data, replaceDefault }));
  };

  return (
    <>
      {imageAttributes?.map(
        (image, idx) =>
          image.attributeId !== SectionIdentifier.MainImage && (
            <RightImageWrapper key={idx}>
              <ImagePreview
                imageData={image.value}
                defaultImageData={image.defaultValue}
                onDeleteImage={() => onDeleteImage(idx)}
                onImageSelect={data => onSaveImageChanges(idx, data, true)}
                onImageEdit={data => onSaveImageChanges(idx, data, false)}
                testId={`image-${idx}`}
              />
            </RightImageWrapper>
          ),
      )}
    </>
  );
};

export default SectionImageList;

const RightImageWrapper = styled.div`
  width: 80%;
  height: 30%;
  margin-bottom: 9%;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #f4f4f5;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
