import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ErrorIcon, GreenCheckIcon } from '../../assets';
import { notificationMsg, resetNotification } from './notificationSlice';

const ToastNotification = () => {
  const notification = useSelector(notificationMsg);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!notification.message) {
      return;
    }
    const timeoutId = setTimeout(() => {
      dispatch(resetNotification());
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [dispatch, notification]);

  if (!notification.message) {
    return null;
  }

  return (
    <Wrapper data-testid="notification">
      {notification.type === 'success' ? <GreenCheckIcon /> : <ErrorIcon />}
      <Text>{notification.message}</Text>
    </Wrapper>
  );
};

export default ToastNotification;

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f47457;
  color: #f47457;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 18px;
  width: fit-content;
  align-self: center;
  position: fixed;
  z-index: 9999;
  top: 10px;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;

  @media print {
    display: none;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
      top: -40px;
    }
    100% {
      opacity: 1;
      top: 10px;
    }
  }
`;

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  white-space: nowrap;
  color: #f47457;
`;
