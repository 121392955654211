type ResponseType = 'json' | 'text' | 'blob';
const baseUrl = process.env.REACT_APP_API_SERVER;

type DefaultHeaders = { DealerId: string; EcmUrl: string } | null;

export class HttpClient {
  private defaultHeaders: DefaultHeaders = null;

  public setDefaultHeadersFromSearchParams(searchParams: URLSearchParams) {
    const ecmUrl = new URL(searchParams.get('returnUrl') || '');
    const dealerId = searchParams.get('dealerId');

    this.defaultHeaders = { DealerId: dealerId ?? '', EcmUrl: ecmUrl.origin };
  }

  private getUrl(relativeUrl: string) {
    return `${baseUrl}${relativeUrl}`;
  }

  public get(
    relativeUrl: string,
    headers = {},
    responseType: ResponseType = 'json',
    options: { signal?: AbortSignal } = {},
  ) {
    return fetch(this.getUrl(relativeUrl), {
      credentials: 'include',
      headers: {
        ...this.defaultHeaders,
        ...headers,
        'Content-Type': 'application/json',
      },
      signal: options.signal,
    }).then(res => this.status(res, responseType));
  }

  public post(
    relativeUrl: string,
    body: object,
    headers = {},
    responseType: ResponseType = 'json',
    options: { signal?: AbortSignal } = {},
  ) {
    return fetch(this.getUrl(relativeUrl), {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        ...this.defaultHeaders,
        ...headers,
        'Content-Type': 'application/json',
      },
      signal: options.signal,
      method: 'POST',
    }).then(res => this.status(res, responseType));
  }

  private status(res: Response, responseType: ResponseType) {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    if (responseType === 'text') {
      return res.text();
    }

    if (responseType === 'blob') {
      return res.blob();
    }

    return res.json();
  }
}

export const httpClient = new HttpClient();
