import { useClickOutside } from 'hooks';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NewFieldPopup } from './NewFieldPopup';
import FocusLock from 'react-focus-lock';
import { useSelector } from 'react-redux';
import { getPageState } from '../../features/pages/pagesSlices';

interface AddNewFieldPropsType {
  onAddNewItem: (attributeLabel: string, value: string) => void;
}

const AddNewField = ({ onAddNewItem }: AddNewFieldPropsType) => {
  const { t } = useTranslation();
  const { isHoverBody } = useSelector(getPageState);
  const [togglePopover, setTogglePopover] = useState(false);
  const addFieldWrapperRef = useRef<HTMLDivElement>(null);

  const handleClosePopover = useCallback(() => setTogglePopover(false), []);

  useClickOutside(addFieldWrapperRef, handleClosePopover);

  return (
    <Wrapper ref={addFieldWrapperRef}>
      <AddField
        isHoverBody={isHoverBody}
        onClick={() => setTogglePopover(true)}
        data-testid="add-field"
      >
        + {t('general.addField')}
      </AddField>
      {togglePopover && (
        <FocusLock>
          <NewFieldPopup
            onAddNewItem={onAddNewItem}
            onClose={handleClosePopover}
          />
        </FocusLock>
      )}
    </Wrapper>
  );
};

export default AddNewField;

const Wrapper = styled.div`
  position: relative;
  margin-top: 3%;
  margin-left: 11%;
`;

const AddField = styled.span<{ isHoverBody: boolean }>`
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 400;
  color: #f48f73;
  cursor: pointer;
  visibility: ${props => (props.isHoverBody ? 'visible' : 'hidden')};
  @media print {
    display: none;
  }
`;
