export const returnUrl = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const returnUrl = params.get('returnUrl') || 'https://ecm.ronati.com';
  return returnUrl;
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
