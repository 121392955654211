import React from 'react';
import styled from 'styled-components';
import { ronatiLoadingImage } from 'assets';

const LoadingOverlay = () => {
  return (
    <Container data-testid="loading-overlay">
      <LoadingWrapper />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  inset: 0;
  background-color: rgba(51, 51, 51, 0.55);
  z-index: 9999;
`;

const LoadingWrapper = styled.div`
  background: url(${ronatiLoadingImage}) center center / contain no-repeat;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 100px;
  height: 100%;
  max-height: 100px;
  z-index: 900;
`;
export default LoadingOverlay;
