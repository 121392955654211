import React from 'react';
import styled from 'styled-components';
import TemplatePagePreview from './TemplatePagePreview';

interface TemplatePreviewProps {
  showFooter: number;
  formats: string[];
  templateType: string;
}

const TemplatePreview = ({
  showFooter,
  formats,
  templateType,
}: TemplatePreviewProps) => {
  return (
    <TemplateWrapper>
      <TemplatePreviewTitle>Template Preview</TemplatePreviewTitle>
      <TemplatePageWrapper>
        {formats.map((format, index) => (
          <TemplatePagePreview
            key={format}
            showFooter={showFooter}
            format={format}
            templateType={templateType}
            page={index + 1}
          />
        ))}
      </TemplatePageWrapper>
    </TemplateWrapper>
  );
};

const TemplateWrapper = styled.div`
  position: relative;
  background-color: #ebebeb;
  width: 300px;
  height: 232px;
`;

const TemplatePreviewTitle = styled.div`
  position: absolute;
  top: 35px;
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-size: 0.75rem;
`;

const TemplatePageWrapper = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export default TemplatePreview;
