import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface FormatSelectionPropsType {
  selectedValue: string[];
  options: { text: string; value: string }[];
  isRemovingTemplatePage: boolean;
  onSelect: (val: string[]) => void;
  name?: string;
}

const FormatSelection = ({
  selectedValue,
  options,
  isRemovingTemplatePage,
  onSelect,
  name,
}: FormatSelectionPropsType) => {
  const { t } = useTranslation();

  const onChange = (value: string) => {
    let newValue = selectedValue.includes(value)
      ? selectedValue.filter(v => v !== value)
      : [...selectedValue, value].sort();
    if (newValue.length > 0) onSelect(newValue);
  };

  return (
    <Container data-testid="format-selection">
      <Label>{t('templateSetting.chooseTemplate')}</Label>
      <ItemWrapper>
        {options.map(opt => (
          <ItemLabel
            checked={selectedValue.includes(opt.value)}
            key={opt.value}
          >
            <Item
              type="checkbox"
              id={name}
              name={name}
              value={opt.value}
              checked={selectedValue.includes(opt.value)}
              onChange={() => onChange(opt.value)}
              data-testid="format-checkbox"
            />
            {opt.text}
          </ItemLabel>
        ))}
      </ItemWrapper>
      {isRemovingTemplatePage && (
        <WarningText>{t('templateSetting.warningChangeTemplate')}</WarningText>
      )}
    </Container>
  );
};

const Container = styled.fieldset`
  margin-top: 20px;
  width: 100%;
  border: none;
`;

const Label = styled.legend`
  font-size: 0.875rem;
  margin-bottom: 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemLabel = styled.label<{ checked: boolean }>`
  font-size: 0.875rem;
  width: 300px;
  height: 41px;
  background: #ffffff;
  border: 1px solid ${props => (props.checked ? '#F47457' : '#C8CACD')};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => (props.checked ? '#F47457' : '#353535')};
  position: relative;
`;

const Item = styled.input`
  clip-path: polygon(0 0);
  position: absolute;
`;

const WarningText = styled.p`
  color: #f47457;
  padding-top: 5px;
  font-size: 0.875rem;
`;

export default FormatSelection;
