import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CheckIcon } from 'assets';
import { NoOutlineInputField } from 'components';
import { useAutoFocus } from 'hooks';

interface NewFieldPopupProps {
  onAddNewItem: (attributeLabel: string, value: string) => void;
  onClose: () => void;
}

interface IFieldState {
  title: string;
  value: string;
  error: any;
}

const initialState = {
  title: '',
  value: '',
  error: {},
};

enum ActionType {
  UpdateTitle = 'UpdateTitle',
  UpdateValue = 'UpdateValue',
  Reset = 'Reset',
  UpdateTitleError = 'UpdateTitleError',
}

const reducer = (
  state: IFieldState,
  action: { type: ActionType; payload: string },
) => {
  switch (action.type) {
    case ActionType.UpdateTitle:
      return {
        ...state,
        title: action.payload,
      };
    case ActionType.UpdateValue:
      return {
        ...state,
        value: action.payload,
      };
    case ActionType.UpdateTitleError:
      return {
        ...state,
        error: {
          title: action.payload,
        },
      };
    case ActionType.Reset:
      return initialState;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const NewFieldPopup = ({
  onAddNewItem,
  onClose,
}: NewFieldPopupProps) => {
  const titleMaxLength = 20;
  const valueMaxLength = 26;
  const [{ title, value, error }, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();
  const valueInputRef = useRef<HTMLInputElement | null>(null);

  const handleSave = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch({ type: ActionType.Reset, payload: '' });
    onAddNewItem(title, value);
    onClose();
  };

  const [titleInputRef] = useAutoFocus();

  return (
    <Popover data-testid="new-field-popup">
      <form onSubmit={handleSave}>
        <InputField
          ref={titleInputRef}
          fontWeight={300}
          value={title}
          tabIndex={0}
          placeholder={t('general.title')}
          uppercase
          maxLength={titleMaxLength}
          onKeyDown={event => {
            event.stopPropagation();
            if (event.key === 'Enter') {
              valueInputRef?.current?.focus();
            }
          }}
          onChange={e => {
            dispatch({
              type: ActionType.UpdateTitle,
              payload: e.target.value,
            });
          }}
          data-testid="attribute-title"
        />
        <CharacterLimitation>
          {title.length}/{titleMaxLength}
        </CharacterLimitation>
        {error && <ErrorField>{error.title}</ErrorField>}
        <InputField
          ref={valueInputRef}
          fontWeight={300}
          value={value}
          tabIndex={0}
          placeholder={t('general.value')}
          maxLength={valueMaxLength}
          onChange={e =>
            dispatch({
              type: ActionType.UpdateValue,
              payload: e.target.value,
            })
          }
          data-testid="attribute-value"
        />
        <CharacterLimitation>
          {value.length}/{valueMaxLength}
        </CharacterLimitation>
        <ButtonWrapper>
          <CancelButton
            type="button"
            tabIndex={0}
            onClick={() => {
              dispatch({ type: ActionType.Reset, payload: '' });
              onClose();
            }}
            data-testid="cancel"
          >
            cancel
          </CancelButton>
          <SaveButton
            disabled={!value || !title}
            type="submit"
            tabIndex={0}
            data-testid="save"
          >
            <CheckIcon />
            save
          </SaveButton>
        </ButtonWrapper>
      </form>
    </Popover>
  );
};

const CharacterLimitation = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  padding-top: 3px;
  text-align: right;
  font-size: 0.75em;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 1;
  background: #ffffff;
  left: -20px;
  top: -10px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
`;

const InputField = styled(NoOutlineInputField)<{
  fontWeight: number;
  uppercase?: boolean;
}>`
  font-size: 1em;
  font-weight: ${props => props.fontWeight};
  color: ${({ theme }) => theme.colors.black};
  line-height: 140%;
  border-bottom: 1px solid #c8cacd;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  width: 100%;

  :focus {
    border-bottom: 1px solid #f47457;
  }

  ::placeholder {
    text-transform: none;
    font-weight: normal;
    color: #a3a3a3;
  }
`;

const BaseButton = styled.button`
  padding: 8px 16px;
  font-weight: 500;
  font-size: 0.75em;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
`;

const CancelButton = styled(BaseButton)`
  border: 1px solid #c8cacd;
  background: #ffffff;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
`;

const SaveButton = styled(BaseButton)<{ disabled: boolean }>`
  border: none;
  color: #ffffff;
  background: ${props => (props.disabled ? '#F6CDC1' : '#f47457')};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'cursor')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  padding-top: 15px;
`;

const ErrorField = styled.span`
  color: #f47457;
  font-weight: 300;
  font-size: 1.125em;
`;
