import { useState } from 'react';

export const useModal = (initialMode = false) => {
  const [isOpen, setModalOpen] = useState(initialMode);
  const toggle = () => setModalOpen(!isOpen);
  const onCloseModal = () => setModalOpen(false);
  const onOpenModal = () => setModalOpen(true);

  return { isOpen, onCloseModal, onOpenModal, toggle };
};
