import styled from 'styled-components';
import { TypoBase } from 'components';

const H2 = styled(TypoBase)`
  display: block;
  font-weight: 800;
  font-size: 30px;
  line-height: 120%;
`;
export default H2;
