import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const name = 'selectImage';

export type SelectImageState = {
  loaded: boolean;
  images: string[];
};

const selectImageInitState: SelectImageState = {
  loaded: false,
  images: [],
};

export const selectImageSlice = createSlice({
  initialState: selectImageInitState,
  name,
  reducers: {
    setImages(state: SelectImageState, action: PayloadAction<string[]>) {
      state.images = action.payload;
      state.loaded = true;
    },
    addImage(state: SelectImageState, action: PayloadAction<string>) {
      state.images = [action.payload, ...state.images];
    },
  },
});

export const { setImages, addImage } = selectImageSlice.actions;

// selectors
export const getSelectImageState = (state: RootState) => state[name];

export default selectImageSlice.reducer;
