import styled from 'styled-components';
import { TypoBase } from 'components';

const H3 = styled(TypoBase)`
  display: block;
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
`;
export default H3;
