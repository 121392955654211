import {
  Button,
  EditImageContent,
  ImageActions,
  Popover,
  SelectImageContent,
  TextRegular1,
} from 'components';
import { useModal } from 'hooks';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getPageState } from '../features/pages/pagesSlices';

interface ImagePreviewProps {
  imageData: string | undefined;
  defaultImageData: string | undefined;
  aspectRatio?: number;
  onDeleteImage: () => void;
  onImageSelect: (data: string) => void;
  onImageEdit: (data: string) => void;
  testId?: string;
}

const ImagePreview = ({
  imageData,
  defaultImageData,
  aspectRatio,
  onDeleteImage,
  onImageSelect,
  onImageEdit,
  testId,
}: ImagePreviewProps) => {
  const {
    isOpen: isEditOpen,
    onCloseModal: onCloseEditModal,
    onOpenModal: onOpenEditModal,
  } = useModal();
  const {
    isOpen: isSelectOpen,
    onCloseModal: onCloseSelectModal,
    onOpenModal: onOpenSelectModal,
  } = useModal();
  const { isHoverBody } = useSelector(getPageState);
  const [showActions, setShowActions] = useState(false);
  const [showDeleteConfirmation, setDeleteConfirmation] = useState(false);
  const handleMouseOverImage = () => {
    setShowActions(true);
  };
  const handleMouseLeaveImage = () => {
    setShowActions(false);
  };

  const handleEditModalClose = () => {
    setShowActions(false);
    onCloseEditModal();
  };

  const onRemoveImage = () => {
    onDeleteImage();
  };

  const handleSelectModalClose = () => {
    setShowActions(false);
    onCloseSelectModal();
  };

  const onSaveSelect = (data: string) => {
    onImageSelect(data);
    onCloseSelectModal();
  };

  const onSaveEdit = (data: string) => {
    onImageEdit(data);
    onCloseEditModal();
  };

  const handleImageActionClick = (action: string) => {
    switch (action) {
      case 'edit':
        onOpenEditModal();
        break;
      case 'remove':
        setDeleteConfirmation(true);
        break;
      case 'replace':
        onOpenSelectModal();
        break;
    }
  };

  const selectImageModal = (
    <Modal
      center
      open={isSelectOpen}
      onClose={handleSelectModalClose}
      styles={{ modal: { padding: '40px 80px' } }}
    >
      <SelectImageContent
        onClose={handleSelectModalClose}
        onSave={onSaveSelect}
      />
    </Modal>
  );

  const renderImage = () => {
    if (imageData) {
      return (
        <>
          <ImageContainer
            onMouseOver={handleMouseOverImage}
            onMouseLeave={handleMouseLeaveImage}
            data-testid={`preview-${testId}`}
          >
            <ImageActionWrapper>
              {showActions && (
                <ImageActions
                  onClick={handleImageActionClick}
                  testId={testId}
                />
              )}

              <Popover
                shown={showDeleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
              >
                <TextRegular1>{t('general.confirmDeleteImage')}</TextRegular1>
                <DeleteButtonWrapper>
                  <Button
                    variant="text"
                    onClick={() => setDeleteConfirmation(false)}
                  >
                    {t('general.cancel')}
                  </Button>
                  <Button
                    onClick={() => {
                      onRemoveImage();
                      setDeleteConfirmation(false);
                    }}
                  >
                    {t('general.delete')}
                  </Button>
                </DeleteButtonWrapper>
              </Popover>
            </ImageActionWrapper>

            {imageData && <img src={imageData} alt="product" />}
          </ImageContainer>
          <EditContainer>
            <Modal
              center
              open={isEditOpen}
              onClose={handleEditModalClose}
              styles={{ modal: { padding: '40px 80px' } }}
            >
              <EditImageContent
                imageData={imageData}
                aspectRatio={aspectRatio}
                defaultImageData={defaultImageData || imageData}
                onClose={handleEditModalClose}
                onSave={onSaveEdit}
              />
            </Modal>
            {selectImageModal}
          </EditContainer>
        </>
      );
    }

    return (
      <>
        <AddImageWrapper
          onMouseOver={handleMouseOverImage}
          onMouseLeave={handleMouseLeaveImage}
          onClick={onOpenSelectModal}
          hide={!isHoverBody}
          data-testid={`add-${testId}`}
        >
          <AddImageLabel>
            <AddImagePlus>+</AddImagePlus>
            <AddImageText>Add Image</AddImageText>
          </AddImageLabel>
        </AddImageWrapper>
        <EditContainer>{selectImageModal}</EditContainer>
      </>
    );
  };

  return renderImage();
};

export default ImagePreview;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
`;

const ImageActionWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  @media print {
    display: none;
  }
`;

const EditContainer = styled.div`
  display: inherit;
  margin: auto;
`;

const AddImageWrapper = styled.div<{ hide: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
  @media print {
    display: none;
  }
`;

const AddImageLabel = styled.label`
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  color: #f47457;
  input {
    display: none;
  }
`;

const AddImagePlus = styled.div`
  font-size: 4em;
  margin: 0;
  padding: 0;
`;

const AddImageText = styled.div`
  font-size: 1.25em;
  text-transform: uppercase;
`;

const DeleteButtonWrapper = styled.div`
  padding-top: 15px;
`;
