import { Button } from 'components/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ImagePreview from '../../components/ImagePreview';
import { getCompanyDetails, updateCompanyLogo } from './companyDetailsSlice';

import { CompanyInfo } from './CompanyInfo';
import { getPageState } from '../pages/pagesSlices';

interface CompanyDetailsProps {
  hideInfo?: boolean;
  hideLogo?: boolean;
  onCompanyInfoToggle: React.MouseEventHandler<HTMLButtonElement>;
  onCompanyLogoToggle: React.MouseEventHandler<HTMLButtonElement>;
}

export function CompanyDetails({
  hideLogo = false,
  hideInfo = false,
  onCompanyInfoToggle,
  onCompanyLogoToggle,
}: CompanyDetailsProps) {
  const { isHoverBody } = useSelector(getPageState);
  const { dealerInfo } = useSelector(getCompanyDetails);
  const dispatch = useDispatch();
  const { t } = useTranslation('translation');

  const saveImageChanges = (data: string, replaceDefault = false) => {
    dispatch(updateCompanyLogo({ data, replaceDefault }));
  };

  return (
    <Container>
      <InfoContainer>
        {dealerInfo && !hideInfo ? <CompanyInfo dealer={dealerInfo} /> : null}

        <ButtonWithMargin
          variant="outlined"
          onClick={onCompanyInfoToggle}
          hide={!isHoverBody}
          data-testid="show-company-details"
        >
          {!hideInfo
            ? t('companyDetails.hideInfo')
            : t('companyDetails.showInfo')}
        </ButtonWithMargin>
      </InfoContainer>

      <LogoContainer>
        <LogoFrame hideLogo={hideLogo}>
          {!hideLogo && (
            <ImagePreview
              aspectRatio={1.3}
              imageData={dealerInfo?.company_logo_url}
              defaultImageData={dealerInfo?.company_logo_url_default}
              onDeleteImage={() => saveImageChanges('')}
              onImageSelect={data => saveImageChanges(data, true)}
              onImageEdit={saveImageChanges}
              testId="company-logo"
            />
          )}
        </LogoFrame>

        <ButtonWithMargin
          variant="outlined"
          onClick={onCompanyLogoToggle}
          hide={!isHoverBody}
          data-testid="show-company-logo"
        >
          {!hideLogo
            ? t('companyDetails.hideLogo')
            : t('companyDetails.showLogo')}
        </ButtonWithMargin>
      </LogoContainer>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 0 15px;
`;

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  width: 35%;
`;

const InfoContainer = styled.div`
  overflow: hidden;
  width: 65%;
`;

const ButtonWithMargin = styled(Button)<{ hide: boolean }>`
  margin-top: 0.5em;
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s;
  opacity: ${({ hide }) => (hide ? '0' : '1')};
  height: auto;
  font-size: 0.75em;
  padding: 0.5em 1.5em;
`;

const LogoFrame = styled.div<{ hideLogo: boolean }>`
  width: 70%;
  height: 70%;
  &:hover {
    background-color: ${props => (props.hideLogo ? 'transparent' : '#f4f4f5')};
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
