import { useDispatch, useSelector } from 'react-redux';
import { changePage, getPageState } from '../features/pages/pagesSlices';

const usePageNextBackNavigation = () => {
  const dispatch = useDispatch();
  const { present, currentPageIndex } = useSelector(getPageState);
  const isEnabled = present.length > 1;
  const isLastPage = currentPageIndex === present.length - 1;
  const isFirstPage = currentPageIndex === 0;

  const handleGoNext = () => {
    const page = isLastPage ? 0 : currentPageIndex + 1;
    dispatch(dispatch(changePage(page)));
  };

  const handleGoBack = () => {
    const page = isFirstPage ? present.length - 1 : currentPageIndex - 1;
    dispatch(dispatch(changePage(page)));
  };

  return {
    isEnabled,
    isLastPage,
    isFirstPage,
    page: currentPageIndex + 1,
    handleGoNext,
    handleGoBack,
  };
};

export default usePageNextBackNavigation;
