import React from 'react';
import styled from 'styled-components';
import { ButtonBase, ButtonInverse } from 'components';

interface GenericConfirmModalProps {
  descriptionText: string;
  leftButtonText: string;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  rightButtonText: string;
  titleText: string;
}

const GenericConfirmModal = (props: GenericConfirmModalProps) => {
  return (
    <GenericConfirmModalContainer data-testid="confirm-modal">
      <GenericConfirmModalTitle>{props.titleText}</GenericConfirmModalTitle>
      <GenericConfirmModalDescription>
        {props.descriptionText}
      </GenericConfirmModalDescription>
      <GenericConfirmModalButtonsContainer>
        <LeftButton onClick={props.onLeftButtonClick} data-testid="cancel">
          {props.leftButtonText}
        </LeftButton>
        <RightButton onClick={props.onRightButtonClick} data-testid="exit">
          {props.rightButtonText}
        </RightButton>
      </GenericConfirmModalButtonsContainer>
    </GenericConfirmModalContainer>
  );
};

export default GenericConfirmModal;

const GenericConfirmModalContainer = styled.div`
  width: 640px;
`;

const GenericConfirmModalTitle = styled.h2`
  margin: 50px 40px 0;
  font-size: 30px;
  white-space: pre-line;
`;

const GenericConfirmModalDescription = styled.div`
  margin: 20px 40px;
  font-size: 18px;
  white-space: pre-line;
`;

const GenericConfirmModalButtonsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  float: right;
`;

const LeftButton = styled(ButtonInverse)`
  padding-left: 16px;
  padding-right: 16px;
`;

const RightButton = styled(ButtonBase)`
  margin-left: 40px;
  padding-left: 16px;
  padding-right: 16px;
`;
