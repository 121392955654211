export const ringSizes = [
  { label: '', value: '0' },
  { label: 'UK F - US 3', value: '44.2' },
  { label: 'UK G 1/2 - US 3 1/2', value: '45.5' },
  { label: 'UK H 1/2 - US 4', value: '46.8' },
  { label: 'UK I 1/2 - US 4 1/2', value: '48' },
  { label: 'UK J 1/2 - US 5', value: '49.3' },
  { label: 'UK L - US 5 1/2', value: '50.6' },
  { label: 'UK M - US 6', value: '51.9' },
  { label: 'UK N - US 6 1/2', value: '53.1' },
  { label: 'UK O - US 7', value: '54.4' },
  { label: 'UK P - US 7 1/2', value: '55.7' },
  { label: 'UK Q - US 8', value: '57' },
  { label: 'UK R - US 8 1/2', value: '58.3' },
  { label: 'UK S - US 9', value: '59.5' },
  { label: 'UK T - US 9 1/2', value: '60.8' },
  { label: 'UK T 1/2 - US 10', value: '62.1' },
  { label: 'UK U 1/2 - US 10 1/2', value: '63.4' },
  { label: 'UK V 1/2 - US 11', value: '64.6' },
  { label: 'UK W 3/4 - US 11 1/2', value: '65.9' },
  { label: 'UK Y - US 12', value: '67.2' },
  { label: 'UK Z - US 12 1/2', value: '68.5' },
  { label: 'UK Z+1 - US 13', value: '69.7' },
];

// diti[item.value];
