import React, { useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { NoOutlineInputField } from 'components';
import { useEditableField } from 'hooks';
import { EditableFieldActionType } from 'types/EditableFieldActionType';
import { TemplateSection } from 'types/TemplateDescription';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { addNotification } from '../features/notification/notificationSlice';
import {
  getPageState,
  updateSectionAttribute,
} from '../features/pages/pagesSlices';
import { SectionIdentifier } from '../types/TemplateSectionType';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface EditableDescriptionProps {
  id: string;
  descriptionSection?: TemplateSection;
}

const EditableDescription = ({
  id,
  descriptionSection,
}: EditableDescriptionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isHoverBody } = useSelector(getPageState);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const titleInputRef = useRef<HTMLInputElement | null>(null);
  const descriptionEditorRef = useRef<TinyMCEEditor | null>(null);
  const [descriptionFocus, setDescriptionFocus] = React.useState(false);
  const [description, setDescription] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState(false);
  const [labelError, setLabelError] = React.useState(false);
  const descriptionValue = descriptionSection?.textAttributes[0].value || '';
  const attributeLabel =
    descriptionSection?.textAttributes[0].attributeLabel || '';
  const attributeId =
    descriptionSection?.textAttributes[0].attributeId ||
    SectionIdentifier.Description;
  const isLabelEditable =
    descriptionSection?.textAttributes[0].isLabelEditable || false;
  const { fieldState, dispatchEditableField } = useEditableField({
    label: attributeLabel,
    value: descriptionValue,
  });

  const checkDescription = () => {
    const hasDescriptionError = !!(
      descriptionEditorRef &&
      descriptionEditorRef.current &&
      descriptionEditorRef.current.targetElm &&
      descriptionEditorRef.current.targetElm.scrollHeight >
        descriptionEditorRef.current.targetElm.clientHeight
    );
    setDescriptionError(hasDescriptionError);
  };
  const checkLabel = () => {
    setLabelError(
      !!(
        titleInputRef &&
        titleInputRef.current &&
        titleInputRef.current.scrollWidth >
          titleInputRef.current.clientWidth + 1
      ),
    );
  };

  useEffect(() => {
    checkDescription();
    checkLabel();
  }, [fieldState.value, fieldState.label]);

  const handleUpdateAttribute = () => {
    if (
      fieldState.label !== attributeLabel ||
      fieldState.value !== description
    ) {
      setDescription(fieldState.value);
      dispatch(
        updateSectionAttribute({
          sectionId: SectionIdentifier.Description,
          attributeId,
          attributeLabel: fieldState.label,
          value: fieldState.value,
        }),
      );
      dispatch(
        addNotification({
          message: `Field "${fieldState.label.toUpperCase()}" is updated`,
        }),
      );
    }
  };

  const handleLabelChange = (e: any) => {
    dispatchEditableField({
      type: EditableFieldActionType.UpdateField,
      field: 'label',
      payload: e.target.value,
    });
    checkLabel();
  };

  const handleDescriptionChange = (value: string) => {
    dispatchEditableField({
      type: EditableFieldActionType.UpdateField,
      field: 'value',
      payload: value,
    });
    checkDescription();
  };

  const handleInitEditor = (evt: any, editor: TinyMCEEditor) => {
    descriptionEditorRef.current = editor;
    setDescription(editor.getContent());
  };

  const scrollToTopEditor = () => {
    if (
      descriptionEditorRef &&
      descriptionEditorRef.current &&
      descriptionEditorRef.current.getBody().firstChild
    ) {
      descriptionEditorRef.current.targetElm.scrollTop = 0;
    }
  };

  return (
    <EditContainer
      isEditing={true}
      ref={containerRef}
      data-testid="editable-description"
    >
      {isLabelEditable ? (
        <>
          <InputField
            placeholder={isHoverBody ? t('placeholder.title') : ''}
            ref={titleInputRef}
            value={fieldState.label}
            hasError={labelError}
            onBlur={handleUpdateAttribute}
            onChange={handleLabelChange}
            data-testid="description-title"
          />
          {labelError && (
            <ErrorMessage>{t('error.characterLimit')}</ErrorMessage>
          )}
        </>
      ) : (
        <Label>{fieldState.label}</Label>
      )}
      <ContentEditableStyle
        id={id}
        hasError={descriptionError}
        hasFocus={descriptionFocus}
        isHoverBody={isHoverBody}
      />
      <TextAreaContainer
        hasError={descriptionError}
        hasFocus={descriptionFocus}
      >
        <Editor
          id={id}
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onInit={handleInitEditor}
          value={fieldState.value}
          onFocus={() => {
            setDescriptionFocus(true);
          }}
          onBlur={() => {
            scrollToTopEditor();
            setDescriptionFocus(false);
            handleUpdateAttribute();
          }}
          onEditorChange={handleDescriptionChange}
          inline={true}
          init={{
            placeholder:
              attributeId === SectionIdentifier.Description
                ? t('placeholder.description')
                : t('placeholder.conditionDetails'),
            promotion: false,
            menubar: false,
            plugins: ['advlist', 'lists', 'link', 'image', 'table', 'charmap'],
            contextmenu: '',
            browser_spellcheck: true,
            toolbar:
              'blocks bold italic underline | alignleft aligncenter alignright | ' +
              'bullist numlist | link table charmap image',
          }}
        />
      </TextAreaContainer>
      {descriptionError && (
        <ErrorMessage>{t('error.characterLimit')}</ErrorMessage>
      )}
    </EditContainer>
  );
};

export default EditableDescription;

const ContentEditableStyle = createGlobalStyle<{
  id: string;
  hasError: boolean;
  hasFocus: boolean;
  isHoverBody: boolean;
}>`
  #${props => props.id}.mce-content-body { 
    overflow-y: ${props =>
      props.hasError && props.hasFocus ? 'scroll' : 'hidden'};
  }

  #${props =>
    props.id}.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    display: ${props => (props.isHoverBody ? 'block' : 'none')}
  }
`;

const EditContainer = styled.div<{ isEditing: boolean }>`
  width: 100%;
  height: 100%;
`;

const TextAreaContainer = styled.div<{
  hasError: boolean;
  hasFocus: boolean;
}>`
  width: ${props =>
    props.hasError && props.hasFocus ? 'calc(100% + 15px)' : '100%'};
  height: 86%;
  font-size: 1em;
  line-height: 120%;
  font-style: normal;
  letter-spacing: 0.015em;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2%;
  outline: none;
  box-shadow: none;
  position: relative;
  background-color: ${props =>
    props.hasFocus ? 'rgba(200, 202, 205, 0.2)' : 'inherit'};
  border-bottom: ${props =>
    props.hasError
      ? '2px solid #ff5050'
      : props.hasFocus
      ? '2px solid #f47457'
      : '2px solid transparent'};
  :hover {
    background-color: rgba(200, 202, 205, 0.2);
    border-bottom: ${props =>
      props.hasError ? '2px solid #ff5050' : '2px solid #f47457'};
  }
  @media print {
    border-bottom: 2px solid transparent !important;
  }
`;

const Label = styled.label`
  width: 100%;
  font-size: 1.28em;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
`;

const InputField = styled(NoOutlineInputField)<{
  hasError: boolean;
}>`
  width: 100%;
  font-size: 1.28em;
  text-transform: capitalize;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  background: inherit;
  cursor: pointer;
  border-bottom: ${props =>
    props.hasError ? '2px solid #ff5050' : '2px solid transparent'};
  :focus,
  :hover {
    background-color: rgba(200, 202, 205, 0.2);
    border-bottom: ${props =>
      props.hasError ? '2px solid #ff5050' : '2px solid #f47457'};
  }
  @media print {
    border-bottom: 2px solid transparent !important;
  }
`;

const ErrorMessage = styled.p`
  color: #ff5050;
  text-align: end;
  background: inherit;
  @media print {
    display: none;
  }
`;
