import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TemplateIdentifier } from '../../types/TemplateSectionType';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

interface TemplatePagePreviewProps {
  showFooter: number;
  format: string;
  page: number;
  templateType: string;
}

const TemplatePagePreview = ({
  showFooter,
  format,
  page,
  templateType,
}: TemplatePagePreviewProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getDateTip = () => {
    switch (format) {
      case TemplateIdentifier.Page1.toString():
        return t('templateSetting.templatePage1Desc');
      case TemplateIdentifier.Page2.toString():
        return t('templateSetting.templatePage2Desc');
      case TemplateIdentifier.Page3.toString():
        return t('templateSetting.templatePage3Desc');
      default:
        return '';
    }
  };
  const dataTip = getDateTip();

  const pageSize = () => {
    switch (templateType) {
      case 'A3':
        return {
          width: '86px',
          height: '120px',
        };
      case 'A4':
        return {
          width: '74px',
          height: '100px',
        };
      case 'A5':
        return {
          width: '62px',
          height: '80px',
        };
      case 'letter':
        return {
          width: '80px',
          height: '90px',
        };
      case 'legal':
        return {
          width: '80px',
          height: '110px',
        };
    }
  };

  return (
    <TemplatePageWrapper data-tip={dataTip}>
      <TemplatePage style={pageSize()}>
        {
          /* Page1, Page2 - Main image */
          [
            TemplateIdentifier.Page1.toString(),
            TemplateIdentifier.Page2.toString(),
          ].includes(format) && (
            <Box top="11%" left="9%" width="56%" height="33%"></Box>
          )
        }

        {
          /* Page1 - AttributeField */
          [TemplateIdentifier.Page1.toString()].includes(format) && (
            <div>
              <Box top="11%" left="72%" width="20%" height="2%"></Box>
              <Box top="17%" left="72%" width="20%" height="2%"></Box>
              <Box top="23%" left="72%" width="20%" height="2%"></Box>
              <Box top="29%" left="72%" width="20%" height="2%"></Box>
              <Box top="35%" left="72%" width="20%" height="2%"></Box>
              <Box top="41%" left="72%" width="20%" height="2%"></Box>
            </div>
          )
        }

        {
          /* Page2 - RightImages */
          [TemplateIdentifier.Page2.toString()].includes(format) && (
            <>
              <Box top="11%" left="72%" width="22%" height="9%"></Box>
              <Box top="23%" left="72%" width="22%" height="9%"></Box>
              <Box top="35%" left="72%" width="22%" height="9%"></Box>
            </>
          )
        }

        {
          /* Page1, Page2 - Description */
          [
            TemplateIdentifier.Page1.toString(),
            TemplateIdentifier.Page2.toString(),
          ].includes(format) && (
            <>
              <Box top="50%" left="9%" width="83%" height="2%"></Box>
              <Box top="56%" left="9%" width="83%" height="2%"></Box>
              <Box top="62%" left="9%" width="83%" height="2%"></Box>
              <Box top="68%" left="9%" width="63%" height="2%"></Box>
            </>
          )
        }

        {
          /* Page3 - Images */
          [TemplateIdentifier.Page3.toString()].includes(format) && (
            <>
              <Box top="11%" left="9%" width="37%" height="27%"></Box>
              <Box top="44%" left="9%" width="37%" height="27%"></Box>
              <Box top="11%" left="53%" width="37%" height="27%"></Box>
              <Box top="44%" left="53%" width="37%" height="27%"></Box>
            </>
          )
        }

        {
          /* Footer */
          showFooter === 1 && (
            <>
              <Box top="87%" left="9%" width="12%" height="1%"></Box>
              <Box top="91%" left="9%" width="30%" height="2%"></Box>
              <Box top="84%" left="79%" width="14%" height="10%"></Box>
            </>
          )
        }
      </TemplatePage>
      <PageNumber>Page {page}</PageNumber>
    </TemplatePageWrapper>
  );
};
const TemplatePageWrapper = styled.div`
  position: relative;
`;

const TemplatePage = styled.div`
  position: relative;
  background-color: #ffffff;
`;

const PageNumber = styled.div`
  margin-top: 8px;
  text-align: center;
  font-weight: 800;
  font-size: 0.5rem;
`;

const Box = styled.div<{
  top: string;
  left: string;
  width: string;
  height: string;
}>`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: #c8cacd;
`;

export default TemplatePagePreview;
