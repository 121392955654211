import * as React from 'react';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { NoOutlineInputField } from './index';
import { useSelector } from 'react-redux';
import { getPageState } from '../features/pages/pagesSlices';

interface ToggleInputFieldProps {
  name: string;
  placeholder: string;
  value: any;
  handleUpdate: () => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

export const ToggleInputField: FC<ToggleInputFieldProps> = ({
  value,
  name,
  placeholder,
  handleUpdate,
  handleChange,
  children,
}) => {
  const { isHoverBody } = useSelector(getPageState);
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onFocusOut = () => {
    setShowInput(false);
    handleUpdate();
  };

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onFocusOut();
      inputRef.current?.blur();
    }
  };

  return (
    <>
      {showInput ? (
        <InputField
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          value={value}
          onBlur={onFocusOut}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          data-testid={name}
        />
      ) : (
        <ValueWrapper
          onClick={() => setShowInput(true)}
          data-testid={`${name}-container`}
        >
          {value ? (
            <>{children}</>
          ) : isHoverBody ? (
            <Placeholder>{placeholder}</Placeholder>
          ) : null}
        </ValueWrapper>
      )}
    </>
  );
};

const commonStyle = css`
  font-style: normal;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InputField = styled(NoOutlineInputField)`
  ${commonStyle};
  width: 100%;
  color: ${({ theme }) => theme.colors.darkGrey};
  background: inherit;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  :focus,
  :hover {
    background-color: rgba(200, 202, 205, 0.2);
    border-bottom: 2px solid #f47457;
  }
  ::placeholder {
    text-transform: none;
    font-weight: normal;
    color: #a3a3a3;
  }
  @media print {
    border-bottom: 2px solid transparent !important;
    ::placeholder {
      color: transparent;
    }
  }
`;

const Placeholder = styled.span`
  ${commonStyle};
  text-transform: none;
  font-weight: normal;
  color: #a3a3a3;
  @media print {
    display: none;
  }
`;

const ValueWrapper = styled.div`
  background: inherit;
  min-height: 1.6em;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  :focus,
  :hover {
    background-color: rgba(200, 202, 205, 0.2);
    border-bottom: 2px solid #f47457;
  }
`;
