import React from 'react';
import styled from 'styled-components';

interface DropdownOption {
  value: string | number;
  text: string;
  disabled: boolean;
}

interface FormSelectProps {
  label: string;
  dropdownValue: string | number;
  options: DropdownOption[];
  onChange: (value: string) => void;
  name?: string;
}

const FormSelect = ({
  label,
  dropdownValue,
  options,
  onChange,
  name,
}: FormSelectProps) => {
  return (
    <FormSelectWrap>
      <FormSelectLabel htmlFor={name}>{label}</FormSelectLabel>
      <FormSelectInput
        id={name}
        name={name}
        value={dropdownValue}
        onChange={e => {
          const element = e.target as HTMLSelectElement;
          onChange(element.value);
        }}
        data-testid="form-select"
      >
        {options.map((option, index) => (
          <FormSelectOption
            key={index}
            disabled={option.disabled}
            value={option.value}
            data-testid="form-option"
          >
            {option.text}
          </FormSelectOption>
        ))}
      </FormSelectInput>
    </FormSelectWrap>
  );
};

export default FormSelect;

const FormSelectWrap = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const FormSelectLabel = styled.label`
  font-size: 0.875rem;
  margin-bottom: 8px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FormSelectInput = styled.select`
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  position: relative;
  max-width: 300px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #c8cacd;
  width: 100%;
  outline: none;
`;

const FormSelectOption = styled.option`
  border-radius: 5px;
  border: none;
  outline: none;
  width: 300px;
  color: rgba(0, 0, 0, 0.6);
`;
