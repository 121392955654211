import { useDispatch } from 'react-redux';
import { useModal } from './useModal';
import { resetAllChanges } from 'features/pages/pagesSlices';

const useConfirmResetAllChange = () => {
  const dispatch = useDispatch();
  const { isOpen, onCloseModal, onOpenModal } = useModal();

  const handleConfirmReset = () => {
    dispatch(resetAllChanges());
    onCloseModal();
  };

  return {
    showConfirmModal: isOpen,
    handleCloseConfirmModal: onCloseModal,
    handleConfirmReset,
    handleResetAllChange: onOpenModal,
  };
};

export default useConfirmResetAllChange;
