import styled from 'styled-components';
import { graySixDotsImage } from 'assets';

const DragHandle = styled.i`
  display: block;
  width: 1em;
  height: 1em;
  cursor: grab;
  background-color: rgb(200, 202, 205);
  mask-image: url(${() => graySixDotsImage});
  mask-size: cover;
  &:hover {
    background-color: rgb(244, 116, 87);
  }
`;

export default DragHandle;
