import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export interface Notification {
  type?: 'success' | 'error';
  message: string | null;
}

const initialState: Notification = {
  type: 'success',
  message: '',
};

export const notificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.type = action.payload.type ? action.payload.type : 'success';
      state.message = action.payload.message;
    },
    resetNotification: () => {
      return initialState;
    },
  },
});

export const notificationMsg = (state: RootState) => state.notification;
export const { addNotification, resetNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
