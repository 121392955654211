import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  EditIcon,
  EditImageIcon,
  RemoveImageIcon,
  ReplaceImageIcon,
} from 'assets';
import { H2Alternative } from 'components';
import { useClickOutside } from 'hooks';

interface ImageActionsPropsType {
  onClick: (actionType: string) => void;
  testId?: string;
}

interface IAction {
  name: string;
  value: string;
  icon: any;
}
const actions: IAction[] = [
  {
    name: 'general.editImage',
    value: 'edit',
    icon: EditImageIcon,
  },
  {
    name: 'general.replaceImage',
    value: 'replace',
    icon: ReplaceImageIcon,
  },
  {
    name: 'general.removeImage',
    value: 'remove',
    icon: RemoveImageIcon,
  },
];

const ImageActions = ({ onClick, testId }: ImageActionsPropsType) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const closePanel = useCallback(() => setIsOpen(false), []);

  useClickOutside(ref, closePanel);

  const onClickAction = (action: string) => {
    setIsOpen(false);
    onClick(action);
  };

  return (
    <Container ref={ref}>
      <IconWrapper
        onClick={() => setIsOpen(!isOpen)}
        data-testid={`edit-${testId}`}
      >
        <EditIcon />
      </IconWrapper>
      {isOpen && (
        <ActionsPanel>
          {actions.map(action => {
            const Icon = action.icon;
            return (
              <ActionItem
                key={action.value}
                onClick={() => onClickAction(action.value)}
                data-testid="image-actions"
              >
                <Icon />
                <H2Alternative
                  style={{
                    color: action.name === 'remove' ? '#F47457' : 'auto',
                  }}
                >
                  {t(action.name)}
                </H2Alternative>
              </ActionItem>
            );
          })}
        </ActionsPanel>
      )}
    </Container>
  );
};

const Container = styled.div``;

const ActionsPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 10;
`;

const ActionItem = styled.div`
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 15px;
  cursor: pointer;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
`;
export default ImageActions;
