import React from 'react';
import styled from 'styled-components';

interface ToggleButtonProps {
  toggleValue: boolean;
  onChange: (newValue: boolean) => void;
}

const ToggleButton = (props: ToggleButtonProps) => {
  return (
    <ToggleButtonWrap>
      <input
        type="checkbox"
        className="toggle-button"
        key={Math.random().toString()}
        checked={props.toggleValue}
        onChange={() => props.onChange(!props.toggleValue)}
        data-testid="apply-title"
      />
    </ToggleButtonWrap>
  );
};

export default ToggleButton;

export const ToggleButtonWrap = styled.div`
  padding-left: 0;
`;
