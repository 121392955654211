import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { AddNewField, EditableAttributeField } from 'components';
import {
  removeTextAttribute,
  updateSectionAttribute,
  updateTextAttributeIndex,
  upsertTextAttribute,
} from 'features/pages/pagesSlices';
import {
  TemplateSection,
  TemplateTextAttribute,
} from 'types/TemplateDescription';
import { SectionIdentifier } from 'types/TemplateSectionType';
import { addNotification } from '../features/notification/notificationSlice';

interface SectionDetailsFieldListProps {
  verticalListSection: TemplateSection | undefined;
}

const SectionDetailsFieldList = ({
  verticalListSection,
}: SectionDetailsFieldListProps) => {
  const dispatch = useDispatch();

  const handleUpdateAttribute = (
    attributeId: string,
    attributeLabel: string,
    value: string,
  ) => {
    dispatch(
      updateSectionAttribute({
        sectionId: SectionIdentifier.VerticalList,
        attributeId,
        attributeLabel,
        value,
      }),
    );
    dispatch(
      addNotification({
        message: `Field "${attributeLabel.toUpperCase()}" is updated`,
      }),
    );
  };

  const handleAddNewField = (attributeLabel: string, value: string) => {
    dispatch(upsertTextAttribute({ attributeLabel, value }));
    dispatch(
      addNotification({
        message: `Field "${attributeLabel.toUpperCase()}" is added`,
      }),
    );
  };

  const handleRemoveAttribute = (item: TemplateTextAttribute) => {
    dispatch(removeTextAttribute(item));
    dispatch(
      addNotification({
        message: `Field "${item.attributeLabel.toUpperCase()}" is removed`,
      }),
    );
  };

  const handleDragItemEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    dispatch(
      updateTextAttributeIndex({
        fromIndex: result.source.index,
        toIndex: result.destination.index,
      }),
    );
  };

  const getDraggableItemStyle = (
    isDragging: boolean,
    dropAnimation: any,
    draggableStyle: any,
  ) => ({
    userSelect: 'none',
    background: isDragging ? '#F4F4F5' : 'none',
    boxShadow: isDragging
      ? 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
      : 'none',
    ...draggableStyle,
    transform:
      isDragging && !dropAnimation
        ? draggableStyle?.transform + ' rotate(2deg)'
        : draggableStyle?.transform,
  });

  return (
    <SectionDetailsWrapper data-testid="section-details">
      <DragDropContext onDragEnd={handleDragItemEnd}>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {verticalListSection?.textAttributes.map(
                (item: TemplateTextAttribute, index) => (
                  <Draggable
                    key={item.attributeId}
                    draggableId={item.attributeId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getDraggableItemStyle(
                          snapshot.isDragging,
                          snapshot.dropAnimation,
                          provided.draggableProps.style,
                        )}
                      >
                        <EditableAttributeField
                          key={item.order}
                          item={item}
                          isDragging={snapshot.isDragging}
                          dragHandleProps={provided.dragHandleProps}
                          onUpdateAttribute={handleUpdateAttribute}
                          onRemove={() => handleRemoveAttribute(item)}
                        />
                      </div>
                    )}
                  </Draggable>
                ),
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {(verticalListSection?.maxAttributesAllowed || 1) >
        verticalListSection!.textAttributes.length && (
        <AddNewField onAddNewItem={handleAddNewField} />
      )}
    </SectionDetailsWrapper>
  );
};

const SectionDetailsWrapper = styled.div`
  height: 100%;
  padding: 1.5% 0;
  border-left: 1px solid ${({ theme }) => theme.colors.darkGrey};
`;

export default SectionDetailsFieldList;
