import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Button, H2 } from 'components';

interface TemplateConfirmationModalProps {
  isOpen: boolean;
  title: string;
  confirmationButtonText?: string;
  onCloseModal: () => void;
  onConfirm: () => void;
}
const TemplateConfirmationModal = ({
  isOpen,
  children,
  title,
  confirmationButtonText,
  onCloseModal,
  onConfirm,
}: PropsWithChildren<TemplateConfirmationModalProps>) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      styles={{ modal: { padding: 0, margin: '5.6rem 1.2rem 1.2rem 1.2rem' } }}
    >
      <TemplateConfirmationModalWrapper>
        <ModalTitle>{title}</ModalTitle>
        <Content>{children}</Content>
        <AppButtons>
          <Button variant="text" onClick={onCloseModal} data-testid="cancel">
            {t('general.cancel')}
          </Button>
          <Button width="140px" onClick={onConfirm} data-testid="confirm">{`${
            confirmationButtonText ? confirmationButtonText : t('general.save')
          }`}</Button>
        </AppButtons>
      </TemplateConfirmationModalWrapper>
    </Modal>
  );
};

const ModalTitle = styled(H2)`
  max-width: 560px;
  padding-bottom: 20px;
`;

const Content = styled.div`
  padding-bottom: 30px;
`;

const TemplateConfirmationModalWrapper = styled.div`
  padding: 50px 40px;
  width: 640px;
`;

const AppButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
export default TemplateConfirmationModal;
