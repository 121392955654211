import { TemplateDescription } from '../types/TemplateDescription';
import { TemplateSettingsState } from '../features/settings';
import { TemplateIdentifier } from '../types/TemplateSectionType';

export const updatePageUnit = (
  page: TemplateDescription,
  unit: string,
): TemplateDescription => {
  const inUnit = unit === 'metric' ? 'inMetric' : 'inImperial';
  return {
    ...page,
    sections: page.sections.map(section => {
      return {
        ...section,
        textAttributes: section.textAttributes.map(attribute => {
          if (attribute.dimension) {
            return { ...attribute, value: attribute.dimension[inUnit] };
          }
          return attribute;
        }),
      };
    }),
  };
};

export const updatePageUnitIfNeeded = (
  page: TemplateDescription,
  oldTemplateSettings: TemplateSettingsState,
  newTemplateSettings: TemplateSettingsState,
): TemplateDescription => {
  if (
    page.templateId === TemplateIdentifier.Page1 &&
    oldTemplateSettings.unit !== newTemplateSettings.unit
  ) {
    return updatePageUnit(page, newTemplateSettings.unit);
  }
  return page;
};
