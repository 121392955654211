import { Dealer } from '../features/companyDetails/companyDetailsSlice';

export const formatAddress = (addressBits: Dealer['company_address']) => {
  if (!addressBits) {
    return '';
  }

  const { street, city, state, zip, country } = addressBits;
  let address = `${street}, ${city}, ${state} ${zip}`;

  if (country) {
    address += `, ${country}`;
  }

  return address;
};
