import styled from 'styled-components';
import { TypoBase } from 'components';

const H4 = styled(TypoBase)`
  display: block;
  font-weight: 300;
  font-size: 19px;
  line-height: 150%;
`;
export default H4;
