import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import 'react-responsive-modal/styles.css';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoadingOverlay } from 'components';
import { isLoading } from 'features/loading/loadingSlice';
import 'i18n';
const HomePage = lazy(() => import('pages/Home'));

import './App.css';
import { useDevelopmentAuth } from 'hooks/useDevelopmentAuth';
import * as Sentry from '@sentry/react';

const theme = {
  colors: {
    black: '#66666A',
    coral: '#F47457',
    darkGrey: '#757575',
    red: '#FF5050',
  },
};

function App() {
  const loading = useSelector(isLoading);
  useDevelopmentAuth();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingOverlay />}>
          {loading && <LoadingOverlay />}
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Sentry.withProfiler(App);
