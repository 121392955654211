import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState = {
  loading: false,
};

export const loadingSlice = createSlice({
  name: 'loadingState',
  initialState,
  reducers: {
    showLoading: () => {
      return {
        loading: true,
      };
    },
    hideLoading: () => {
      return {
        loading: false,
      };
    },
  },
});

export const { showLoading, hideLoading } = loadingSlice.actions;
export const isLoading = (state: RootState) => state.loadingState.loading;
export default loadingSlice.reducer;
