import { useEffect, useReducer, useState } from 'react';
import { EditableState } from 'types/EditableState';
import { MouseActionType } from 'types/MouseActionType';

const actionStateReducer = (
  state: EditableState,
  action: { type: MouseActionType },
) => {
  switch (action.type) {
    case MouseActionType.MouseOver:
      if (state === EditableState.Editing) {
        return state;
      }
      return EditableState.Hovered;
    case MouseActionType.MouseLeave:
      if (state === EditableState.Editing) {
        return state;
      }
      return EditableState.Left;
    case MouseActionType.Edit:
      return EditableState.Editing;
    case MouseActionType.Blur:
      return EditableState.Standard;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const useSingleEditableField = (title: string) => {
  const [value, setValue] = useState(title);
  const [actionState, dispatchActionState] = useReducer(
    actionStateReducer,
    EditableState.Standard,
  );

  useEffect(() => {
    setValue(title);
  }, [title]);

  return {
    value,
    setValue,
    actionState,
    dispatchActionState,
  };
};
