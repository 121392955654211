export enum TemplateIdentifier {
  Page1 = 'product-page-1',
  Page2 = 'product-page-2',
  Page3 = 'product-page-3',
}

export enum SectionIdentifier {
  MainImage = 'main-image',
  Description = 'description',
  ConditionDetail = 'condition-detail',
  BrochureTitle = 'brochure-title',
  SelectionImage = 'imagesSection',
  VerticalList = 'attributesVerticalList',
}

export enum AttributeIdentifier {
  Title = 'title',
}
