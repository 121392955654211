import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-responsive-modal';
import styled from 'styled-components';
import { Button, H2, TextBig2 } from 'components';

interface DownloadPdfConfirmModalPropsTypes {
  isOpen: boolean;
  onDownload: () => void;
  onCloseModal: () => void;
}

const DownloadPdfConfirmModal = ({
  isOpen,
  onDownload,
  onCloseModal,
}: DownloadPdfConfirmModalPropsTypes) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      styles={{ modal: { padding: 0, margin: '5.6rem 1.2rem 1.2rem 1.2rem' } }}
    >
      <ModalWrapper data-testid="download-pdf-modal">
        <ModalTitle>{t('modal.downloadPdf.title')}</ModalTitle>
        <Content>
          <TextBig2>{t('modal.downloadPdf.content')}</TextBig2>
        </Content>
        <AppButtons>
          <Button
            variant="text"
            onClick={onCloseModal}
            data-testid="back-to-editing"
          >
            {t('modal.downloadPdf.buttonBack')}
          </Button>
          <Button onClick={onDownload} data-testid="download">
            {t('modal.downloadPdf.buttonDownload')}
          </Button>
        </AppButtons>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  padding: 50px 40px;
  width: 640px;
`;

const ModalTitle = styled(H2)`
  padding-bottom: 20px;
`;

const Content = styled.div`
  padding-bottom: 30px;
`;

const AppButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export default DownloadPdfConfirmModal;
