import React from 'react';

/**
 * In production when we access the Presentation Creator through eCM
 * it sends an auth cookie that we use to call the backend. When
 * developing locally this cookie is not set thus we need to use this
 * hook instead.
 *
 * @remarks
 * This is only meant to be used once in the application. It should be used in root
 * component: App.
 */
export const useDevelopmentAuth =
  process.env.NODE_ENV === 'development'
    ? () => {
        React.useEffect(() => {
          document.cookie = `ECM-ACCESS-TOKEN=${process.env.REACT_APP_DEV_ECM_TOKEN}`;
        }, []);
      }
    : () => {};
