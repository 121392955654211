import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { RootState } from 'store';
import { formatAddress } from '../../utils/formatAddress';

const name = 'companyDetails';

export interface Dealer {
  company_name?: string;
  phone_number?: string;
  email: string;
  company_url?: string;
  company_logo_url?: string;
  company_logo_url_default?: string;
  company_address: {
    city: string;
    country: string;
    country_name: string;
    state: string;
    street: string;
    zip: string;
    formattedAddress?: string;
  };
}

export interface CompanyDetailsState {
  loaded: boolean;
  dealerInfo?: Dealer;
}

const initialState: CompanyDetailsState = {
  loaded: false,
};

export const companyDetailsSlice = createSlice({
  name,
  initialState,
  reducers: {
    saveCompanyDetails: (
      state: CompanyDetailsState,
      action: PayloadAction<Dealer>,
    ) => {
      return produce(state, draft => {
        draft.dealerInfo = {
          ...action.payload,
          company_address: {
            ...action.payload.company_address,
            formattedAddress: formatAddress(action.payload.company_address),
          },
        };
        draft.loaded = true;
      });
    },
    updateCompanyDetails: (
      state: CompanyDetailsState,
      action: PayloadAction<{
        companyName?: string;
        companyAddress?: string;
        phoneNumber?: string;
        companyUrl?: string;
      }>,
    ) => {
      return produce(state, draft => {
        if (draft.dealerInfo) {
          draft.dealerInfo.company_name = action.payload.companyName;
          draft.dealerInfo.company_address.formattedAddress =
            action.payload.companyAddress;
          draft.dealerInfo.company_url = action.payload.companyUrl;
          draft.dealerInfo.phone_number = action.payload.phoneNumber;
        }
      });
    },
    updateCompanyLogo: (
      state: CompanyDetailsState,
      action: PayloadAction<{ data: string; replaceDefault: boolean }>,
    ) => {
      return produce(state, draft => {
        if (draft.dealerInfo) {
          draft.dealerInfo.company_logo_url = action.payload.data;
          if (action.payload.replaceDefault) {
            draft.dealerInfo.company_logo_url_default = action.payload.data;
          }
        }
      });
    },
  },
});

export const { saveCompanyDetails, updateCompanyDetails, updateCompanyLogo } =
  companyDetailsSlice.actions;

// selectors
export const getCompanyDetails = (state: RootState) => state[name];

export default companyDetailsSlice.reducer;
