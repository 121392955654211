import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

const name = 'templateSettings';
export interface TemplateSettingsState {
  submitted: boolean;
  presentationName: string;
  formats: string[];
  templateType: string;
  showCompanyInfo: number;
  showCompanyName: boolean;
  showCompanyLogo: boolean;
  unit: string;
  applySameTitle: boolean;
}

export type SaveTemplateSettingsPayload = Omit<
  TemplateSettingsState,
  'submitted'
>;

const initialState: TemplateSettingsState = {
  submitted: false,
  presentationName: 'Brochure',
  formats: ['product-page-1'],
  templateType: 'letter',
  unit: 'imperial',
  showCompanyInfo: 0,
  showCompanyName: false,
  showCompanyLogo: false,
  applySameTitle: true,
};

export const templateSettingsSlice = createSlice({
  name,
  initialState,
  reducers: {
    saveSettings: (
      state: TemplateSettingsState,
      action: PayloadAction<SaveTemplateSettingsPayload | any>,
    ) => {
      return {
        ...state,
        ...action.payload,
        showCompanyName: !!action.payload.showCompanyInfo,
        showCompanyLogo: !!action.payload.showCompanyInfo,
        submitted: true,
      };
    },
    updateSettings: (
      state: TemplateSettingsState,
      action: PayloadAction<TemplateSettingsState>,
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    toggleCompanyName: (state: TemplateSettingsState) => {
      const isCompanyInfo = !state.showCompanyName === state.showCompanyLogo;
      if (isCompanyInfo) {
        state.showCompanyInfo = !state.showCompanyName ? 1 : 0;
      }
      state.showCompanyName = !state.showCompanyName;
    },
    toggleCompanyLogo: (state: TemplateSettingsState) => {
      const isCompanyInfo = !state.showCompanyLogo === state.showCompanyName;
      if (isCompanyInfo) {
        state.showCompanyInfo = !state.showCompanyLogo ? 1 : 0;
      }
      state.showCompanyLogo = !state.showCompanyLogo;
    },
  },
});

export const {
  saveSettings,
  updateSettings,
  toggleCompanyName,
  toggleCompanyLogo,
} = templateSettingsSlice.actions;

// selectors
export const getTemplateSettings = (state: RootState) => state[name];

export default templateSettingsSlice.reducer;
