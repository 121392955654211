import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';
import { Button, FormInput, FormSelect, FormatSelection, H2 } from 'components';
import { getPageState } from 'features/pages/pagesSlices';
import {
  TemplateSettingsState,
  getTemplateSettings,
} from './templateSettingsSlice';
import { TemplateIdentifier } from 'types/TemplateSectionType';
import ToggleButton from '../../components/ToggleButton';
import TemplatePreview from './TemplatePreview';
import { isRemovingTemplate } from '../../utils/removeTemplate';

interface TemplateSettingsPropsType {
  onCancel: () => void;
  onSave: (data: TemplateSettingsState) => void;
  isLoadedTemplateSettings?: boolean;
}

const templateTypeOptions = [
  {
    text: 'A3',
    value: 'A3',
    disabled: false,
  },
  {
    text: 'A4',
    value: 'A4',
    disabled: false,
  },
  {
    text: 'A5',
    value: 'A5',
    disabled: false,
  },
  {
    text: 'Letter',
    value: 'letter',
    disabled: false,
  },
];

export const TemplateSettings = ({
  onSave,
  onCancel,
  isLoadedTemplateSettings,
}: TemplateSettingsPropsType) => {
  const { t } = useTranslation();
  const templateSettings = useSelector(getTemplateSettings);
  const { generated } = useSelector(getPageState);
  const [settings, setSettings] =
    useState<TemplateSettingsState>(templateSettings);
  const isRemovingTemplatePage =
    generated && isRemovingTemplate(templateSettings, settings);

  const handleFieldChange = (field: string, value: any) => {
    setSettings({ ...settings, [field]: value });
  };

  const unitsOptions = [
    { text: t('general.metric'), value: 'metric', disabled: false },
    { text: t('general.imperial'), value: 'imperial', disabled: false },
  ];

  const showCompanyInfoOptions = [
    { text: t('general.no'), value: 0, disabled: false },
    { text: t('general.yes'), value: 1, disabled: false },
  ];

  const formatOptions = [
    {
      text: 'Image and Attributes',
      value: TemplateIdentifier.Page1,
    },
    {
      text: 'Image and Description',
      value: TemplateIdentifier.Page2,
    },
    {
      text: 'Images Only',
      value: TemplateIdentifier.Page3,
    },
  ];

  return (
    <Container data-testid="template-settings">
      <TitleWrapper>
        <H2>{t('templateSetting.title')}</H2>
        <SubHeading>{t('templateSetting.subTitle')}</SubHeading>
      </TitleWrapper>
      <MainContent>
        <LeftTemplateSettings>
          <PresentationNameWrapper>
            <FormInput
              label={t('templateSetting.presentationName')}
              id="presentationName"
              inputValue={settings.presentationName}
              disabled={false}
              isMultiLine={false}
              isLabelEditable={false}
              onChange={(value: string) =>
                handleFieldChange('presentationName', value)
              }
              onLabelChange={() => {}}
            />
            <IconWrapper>
              <FaInfoCircle
                color="#2196F3"
                data-tip={t('templateSetting.presentationNameInfo')}
              ></FaInfoCircle>
            </IconWrapper>
          </PresentationNameWrapper>
          <FormatSelection
            name="templateType"
            options={formatOptions}
            onSelect={(value: string[]) => handleFieldChange('formats', value)}
            selectedValue={settings.formats}
            isRemovingTemplatePage={isRemovingTemplatePage}
          />
          <FormSelect
            name="showCompanyInfo"
            label={t('templateSetting.showCompanyInfo')}
            dropdownValue={settings.showCompanyInfo}
            onChange={(value: string) =>
              handleFieldChange('showCompanyInfo', +value)
            }
            options={showCompanyInfoOptions}
          />
          <FormSelect
            name="units"
            label={t('templateSetting.units')}
            dropdownValue={settings.unit}
            onChange={(value: string) => handleFieldChange('unit', value)}
            options={unitsOptions}
          />
          <FormSelect
            name="format"
            label={t('templateSetting.format')}
            dropdownValue={settings.templateType}
            onChange={value => handleFieldChange('templateType', value)}
            options={templateTypeOptions}
          />
        </LeftTemplateSettings>
        <RightTemplateSettings>
          <TemplatePreview
            showFooter={settings.showCompanyInfo}
            formats={settings.formats}
            templateType={settings.templateType}
          />
          <div>
            <ToggleButtonWrapper>
              <ToggleButton
                toggleValue={settings.applySameTitle}
                onChange={value => handleFieldChange('applySameTitle', value)}
              />
              <ToggleButtonLabel>
                {t('templateSetting.applyTitle')}
              </ToggleButtonLabel>
              <FaInfoCircle
                color="#2196F3"
                data-tip={t('templateSetting.applyTitleInfo')}
              ></FaInfoCircle>
            </ToggleButtonWrapper>
            <ActionsWrapper>
              {isLoadedTemplateSettings && (
                <Button variant="text" onClick={onCancel} data-testid="cancel">
                  {t('general.cancel')}
                </Button>
              )}
              <Button
                width="156px"
                onClick={() => onSave(settings)}
                data-testid="save"
              >{`${
                generated ? t('general.save') : t('general.continue')
              }`}</Button>
            </ActionsWrapper>
          </div>
        </RightTemplateSettings>
        <ReactTooltip multiline={true} effect="solid" />
      </MainContent>
    </Container>
  );
};

const Container = styled.div`
  width: 700px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 54px;
  margin-left: 40px;
  gap: 4px;
`;

const PresentationNameWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 56%;
`;

const SubHeading = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
`;

const MainContent = styled.div`
  display: flex;
  padding: 40px;
`;

const LeftTemplateSettings = styled.div`
  width: 300px;
`;

const RightTemplateSettings = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
`;

const ToggleButtonWrapper = styled.div`
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const ToggleButtonLabel = styled.div`
  margin: 0 5px 2px 10px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
