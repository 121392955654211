import { TemplateSettingsState } from '../features/settings';

export const isRemovingTemplate = (
  settingsOld: TemplateSettingsState,
  settingsNew: TemplateSettingsState,
) => {
  return !(
    settingsOld.submitted &&
    settingsOld.formats.every(format => settingsNew.formats.includes(format))
  );
};
