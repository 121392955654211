import {
  EditableDescription,
  EditableTitle,
  SectionDetailsFieldList,
} from 'components';
import ImagePreview from 'components/ImagePreview';
import SectionImageList from 'components/SectionImageList';
import { CompanyDetails } from 'features/companyDetails';
import {
  getImageSectionKey,
  getPageState,
  setIsHoverBody,
  updateImageAttributes,
} from 'features/pages/pagesSlices';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  TemplateDescription,
  TemplateSection,
  TemplateTextAttribute,
} from 'types/TemplateDescription';
import {
  SectionIdentifier,
  TemplateIdentifier,
} from 'types/TemplateSectionType';
import {
  getTemplateSettings,
  toggleCompanyLogo,
  toggleCompanyName,
} from '../features/settings';
import { ringSizes } from './ringSize/ringSizes';
import ImagesTemplate from './Template/ImagesTemplate';
import { generateKey } from '../utils/generateKey';

interface ProductPreviewProps {
  page: TemplateDescription;
}

const ProductPreview = ({ page }: ProductPreviewProps) => {
  const dispatch = useDispatch();
  const templateSettings = useSelector(getTemplateSettings);
  const { isHoverBody } = useSelector(getPageState);
  const { templateId } = page;
  const titleSection =
    page && page.sections.length > 0
      ? page.sections.find(s => s.sectionId === SectionIdentifier.BrochureTitle)
      : undefined;
  const descriptionSection =
    page && page.sections.length > 0
      ? page.sections.find(
          s => s.sectionId === SectionIdentifier.ConditionDetail,
        ) ||
        page.sections.find(s => s.sectionId === SectionIdentifier.Description)
      : undefined;

  const imageSectionId = getImageSectionKey(page?.templateId);
  const imageSection = page?.sections.find(s => s.sectionId === imageSectionId);
  const imageAttributes = imageSection?.imageAttributes;
  const mainImage = imageAttributes?.find(image =>
    [SectionIdentifier.MainImage, 'image'].includes(image.attributeId),
  );
  const verticalListSection = page?.sections.find(
    s => s.sectionId === SectionIdentifier.VerticalList,
  );

  let updatedAttributes: TemplateTextAttribute[] = [];

  if (verticalListSection && verticalListSection.textAttributes) {
    updatedAttributes = verticalListSection.textAttributes.map(attr => {
      if (attr.attributeId === 'ring-size') {
        const matchingRingSize = ringSizes.find(rs => rs.value === attr.value);
        return {
          ...attr,
          value: matchingRingSize?.label || attr.value,
        };
      }
      return attr;
    });
  }

  const updatedVerticalList: TemplateSection | undefined = verticalListSection
    ? {
        ...verticalListSection,
        textAttributes: updatedAttributes,
      }
    : undefined;

  const onDeleteImage = (imageIndex: number) => {
    dispatch(
      updateImageAttributes({ imageIndex, data: '', replaceDefault: false }),
    );
  };

  const onSaveImageChanges = (
    imageIndex: number,
    data: string,
    replaceDefault: boolean,
  ) => {
    dispatch(updateImageAttributes({ imageIndex, data, replaceDefault }));
  };

  const mainImageIndex = 0;

  return (
    <Body
      onMouseOver={e => {
        isHoverBody === false && dispatch(setIsHoverBody(true));
        e.stopPropagation();
      }}
      onMouseLeave={() =>
        isHoverBody === true && dispatch(setIsHoverBody(false))
      }
      data-testid="product-preview"
    >
      <TitleWrapper>
        <EditableTitle section={titleSection} />
      </TitleWrapper>

      {
        /* Page1, Page2  */
        [
          TemplateIdentifier.Page1.toString(),
          TemplateIdentifier.Page2.toString(),
        ].includes(templateId) && (
          <>
            <MainProductInfo>
              <LeftSide>
                <LeftImageWrapper>
                  <ImagePreview
                    aspectRatio={0.87}
                    imageData={mainImage?.value}
                    defaultImageData={mainImage?.defaultValue}
                    onDeleteImage={() => onDeleteImage(mainImageIndex)}
                    onImageEdit={data =>
                      onSaveImageChanges(mainImageIndex, data, false)
                    }
                    onImageSelect={data =>
                      onSaveImageChanges(mainImageIndex, data, true)
                    }
                    testId="main-image"
                  />
                </LeftImageWrapper>
              </LeftSide>
              <RightSide>
                {[TemplateIdentifier.Page1.toString()].includes(templateId) ? (
                  <SectionDetailsFieldList
                    verticalListSection={updatedVerticalList}
                  />
                ) : (
                  <SectionImageList imageSection={imageSection} />
                )}
              </RightSide>
            </MainProductInfo>
            <DescriptionWrapper>
              <EditableDescription
                id={generateKey(page)}
                descriptionSection={descriptionSection}
              />
            </DescriptionWrapper>
          </>
        )
      }

      {
        /* Page3  */
        [TemplateIdentifier.Page3.toString()].includes(templateId) && (
          <ImagesTemplateWrapper>
            <ImagesTemplate imageSection={imageSection}></ImagesTemplate>
          </ImagesTemplateWrapper>
        )
      }

      <FooterWrapper>
        <CompanyDetails
          hideInfo={!templateSettings.showCompanyName}
          hideLogo={!templateSettings.showCompanyLogo}
          onCompanyInfoToggle={() => {
            dispatch(toggleCompanyName());
          }}
          onCompanyLogoToggle={() => {
            dispatch(toggleCompanyLogo());
          }}
        />
      </FooterWrapper>
    </Body>
  );
};

const Body = styled.div`
  height: 100%;
  background: #ffffff;
  border: 1px solid #c8cacd;
  position: relative;
  display: flex;
  flex-direction: column;
  @media print {
    border: 1px solid transparent;
  }
`;

const TitleWrapper = styled.div`
  height: 13%;
  padding: 2.5em 3em 0.625em 3em;
  display: flex;
`;

const MainProductInfo = styled.div`
  height: 48%;
  padding: 0 0 1.625em 3em;
  display: flex;
`;

const ImagesTemplateWrapper = styled.div`
  height: 72%;
  padding: 0 3em 1.625em 3em;
`;

const DescriptionWrapper = styled.div`
  height: 24%;
  padding: 0 3em 1.625em 3em;
`;

const FooterWrapper = styled.div`
  height: 15%;
  padding: 0.5em 3em 0.5em 3em;
`;

const LeftSide = styled.div`
  width: 62%;
  margin-right: 4%;
`;

const RightSide = styled.div`
  width: 38%;
`;

const LeftImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  &:hover {
    background-color: #f4f4f5;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export default ProductPreview;
