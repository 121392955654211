import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { updateSectionAttribute } from 'features/pages/pagesSlices';
import { useClickOutside, useSingleEditableField } from 'hooks';
import { EditableState } from 'types/EditableState';
import { MouseActionType } from 'types/MouseActionType';
import { TemplateSection } from 'types/TemplateDescription';
import {
  AttributeIdentifier,
  SectionIdentifier,
} from 'types/TemplateSectionType';
import { addNotification } from '../features/notification/notificationSlice';
import { RootState } from '../store';

interface EditableTitleProps {
  section?: TemplateSection;
}

const EditableTitle = ({ section }: EditableTitleProps) => {
  const dispatch = useDispatch();
  const templateSettingsState = useSelector(
    (state: RootState) => state.templateSettings,
  );
  const [showInput, setShowInput] = useState(false);
  const title = section ? section!.textAttributes[0].value : '';
  const [titleFocus, setTitleFocus] = React.useState(false);
  const [titleWarning, setTitleWarning] = useState(false);
  const maxLength = 200;
  const { value, setValue, actionState, dispatchActionState } =
    useSingleEditableField(title);
  const inputRef: any = useRef(null);

  const handleDispatchUpdateTitle = useCallback(
    (applySameTitle: boolean) => {
      dispatch(
        updateSectionAttribute({
          sectionId: SectionIdentifier.BrochureTitle,
          attributeId: AttributeIdentifier.Title,
          value,
          applyAllPages: applySameTitle,
        }),
      );
      dispatch(
        addNotification({
          message: 'Your title has been updated',
        }),
      );
    },
    [dispatch, value],
  );

  const handleFocusLeave = useCallback(() => {
    dispatchActionState({ type: MouseActionType.Blur });
    setShowInput(false);
    if (title !== value) {
      handleDispatchUpdateTitle(templateSettingsState.applySameTitle);
    }
    inputRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [
    title,
    value,
    templateSettingsState.applySameTitle,
    dispatchActionState,
    handleDispatchUpdateTitle,
  ]);

  const handleMouseOver = () => {
    dispatchActionState({ type: MouseActionType.MouseOver });
  };

  const handleMouseLeave = () => {
    dispatchActionState({ type: MouseActionType.MouseLeave });
    if (!isEditing) {
      inputRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const checkTitleRows = () => {
    const scrollHeight = inputRef.current.scrollHeight;
    const clientHeight = inputRef.current.clientHeight;

    if (scrollHeight > clientHeight) {
      setTitleWarning(true);
    } else {
      setTitleWarning(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      checkTitleRows();
    }
  });

  const handleTitleChange = (e: any) => {
    checkTitleRows();
    setValue(e.target.value);
  };

  useClickOutside(inputRef, handleFocusLeave);

  useEffect(() => {
    if (showInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInput]);

  const isEditing = actionState === EditableState.Editing;
  const hasError = value.length > maxLength;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleFocusLeave();
      inputRef.current.blur();
    }
  };

  return (
    <EditContainer isEditing={isEditing}>
      <TextAreaField
        ref={inputRef}
        value={value}
        hasScrollbar={titleWarning}
        hasFocus={titleFocus}
        maxLength={maxLength}
        hasError={hasError}
        onKeyDown={handleKeyDown}
        onChange={handleTitleChange}
        onFocus={() => {
          setTitleFocus(true);
        }}
        onBlur={() => {
          setTitleFocus(false);
          handleFocusLeave();
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={() => dispatchActionState({ type: MouseActionType.Edit })}
        data-testid="editable-title"
      />
      {titleWarning && (
        <TitleWarning>
          Your title is too long, only the first 2 rows will be shown
        </TitleWarning>
      )}
      {(isEditing || hasError) && (
        <CharacterLimitation hasError={hasError}>
          {value.length}/{maxLength}
        </CharacterLimitation>
      )}
    </EditContainer>
  );
};

export default EditableTitle;

const EditContainer = styled.div<{ isEditing: boolean }>`
  width: 100%;
  height: 100%;
`;

const TextAreaField = styled.textarea<{
  hasError: boolean;
  hasScrollbar: boolean;
  hasFocus: boolean;
}>`
  width: ${props =>
    props.hasScrollbar && props.hasFocus ? 'calc(100% + 15px)' : '100%'};
  overflow-y: ${props =>
    props.hasScrollbar && props.hasFocus ? 'scroll' : 'hidden'};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.6em;
  background: inherit;
  border: 0;
  outline: none;
  resize: none;
  background-color: ${props =>
    props.hasFocus ? 'rgba(200, 202, 205, 0.2)' : 'inherit'};
  border-bottom: ${props =>
    props.hasError || props.hasScrollbar
      ? '2px solid #ff5050'
      : props.hasFocus
      ? '2px solid #f47457'
      : '2px solid transparent'};
  @media print {
    border-bottom: 2px solid transparent !important;
  }
`;

const TitleWarning = styled.p`
  color: #ff5050;
  position: absolute;
  @media print {
    display: none;
  }
`;

const CharacterLimitation = styled.p<{ hasError: boolean }>`
  color: ${props => (props.hasError ? '#ff5050' : props.theme.colors.darkGrey)};
  text-align: end;
  background: inherit;
  @media print {
    display: none;
  }
`;
