export interface TemplateDescription {
  productId: string;
  templateId: string;
  productThumbnail: string;
  sections: TemplateSection[];
  companyDetails: {
    showInfo: boolean;
    showLogo: boolean;
  };
}

export interface TemplateSection {
  sectionId: string;
  sectionTab: TemplateSectionTab;
  textAttributes: TemplateTextAttribute[];
  imageAttributes: TemplateImageAttribute[];
  maxAttributesAllowed: number;
}

export interface TemplateTextAttribute {
  attributeId: string;
  attributeLabel: string;
  value: string;
  order: number;
  isLabelEditable: boolean;
  isMultiLine: boolean;
  textMaxLength: number;
  enabled: boolean;
  dimension: { inImperial: string; inMetric: string } | null;
}

export interface TemplateImageAttribute {
  attributeId: string;
  defaultValue: string;
  value: string;
  aspectRatio: number;
  order: number;
  enabled: boolean | true;
}

export enum TemplateSectionTab {
  Details = 'Details',
  Images = 'Images',
}
