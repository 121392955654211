import styled from 'styled-components';

const NoOutlineInputField = styled.input`
  outline: none;
  box-shadow: none;
  cursor: pointer;
  :focus {
    outline: none;
    box-shadow: none;
  }
  ::placeholder {
    text-transform: none;
    font-weight: normal;
    color: #a3a3a3;
  }
  @media print {
    ::placeholder {
      color: transparent;
    }
  }
`;

export default NoOutlineInputField;
