import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import GenericConfirmModal from './GenericConfirmModal';
import { RootState } from 'store';

interface WarningAppExitModalProps {
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
}

const WarningAppExitModal = (props: WarningAppExitModalProps) => {
  const modalsRedux = useSelector((state: RootState) => state.modals);
  const descriptionText =
    'Your changes will not be saved. Be sure that you have downloaded ' +
    '\n the presentation before closing this tab.';
  const titleText = 'Are you sure want to exit the \n Presentation Creator?';

  return (
    <Modal
      center={false}
      onClose={props.onLeftButtonClick!}
      open={modalsRedux.warningAppExitOpen}
      styles={{ modal: { padding: 0, margin: '5.6rem 1.2rem 1.2rem 1.2rem' } }}
    >
      <GenericConfirmModal
        descriptionText={descriptionText}
        leftButtonText="cancel"
        onLeftButtonClick={props.onLeftButtonClick}
        onRightButtonClick={props.onRightButtonClick}
        rightButtonText="exit presentation"
        titleText={titleText}
      />
    </Modal>
  );
};

export default WarningAppExitModal;
