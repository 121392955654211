import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MovableIcon, CloseIcon as RemoveIcon } from 'assets';
import {
  Button,
  NoOutlineInputField,
  Popover,
  TextRegular1,
  UnderlineInputField,
} from 'components';
import {
  useClickOutside,
  useEditableField,
  useEditableFieldAction,
  useHoveringField,
} from 'hooks';
import { EditableFieldActionType } from 'types/EditableFieldActionType';
import { EditableState } from 'types/EditableState';
import { MouseActionType } from 'types/MouseActionType';
import { TemplateTextAttribute } from 'types/TemplateDescription';

interface EditableAttributeFieldProps {
  item: TemplateTextAttribute;
  onUpdateAttribute: (
    attributeId: string,
    attributeLabel: string,
    value: string,
  ) => void;
  onRemove: () => void;
  isDragging: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | undefined;
}

const EditableAttributeField = ({
  item,
  onUpdateAttribute,
  onRemove,
  isDragging,
  dragHandleProps,
}: EditableAttributeFieldProps) => {
  const { attributeId, value, attributeLabel } = item;
  const { t } = useTranslation();
  const { fieldState, dispatchEditableField } = useEditableField({
    value,
    label: attributeLabel,
  });
  const removeFieldPopoverRef = useRef<HTMLDivElement>(null);
  const { fieldActionState, dispatchFieldAction } = useEditableFieldAction();
  const {
    isHovering: isHoveringField,
    handleMouseLeaveField,
    handleMouseOverField,
  } = useHoveringField();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const titleMaxLength = 20;
  const valueMaxLength = 26;

  useEffect(() => {
    if (isDragging) {
      setIsConfirmationVisible(false);
    }
  }, [isDragging]);

  const handleConfirmRemove = () => setIsConfirmationVisible(true);

  const handleRemove = () => {
    setIsConfirmationVisible(false);
    onRemove();
  };

  const handleCancel = () => {
    setIsConfirmationVisible(false);
    handleMouseLeaveField();
  };

  useClickOutside(removeFieldPopoverRef, handleCancel);
  const editBoxRef = useRef<HTMLDivElement | null>(null);
  const valueInputRef = useRef<HTMLInputElement | null>(null);

  const handleValueChange = (e: any) => {
    dispatchEditableField({
      type: EditableFieldActionType.UpdateField,
      field: 'value',
      payload: e.target.value,
    });
  };

  const handleLabelChange = (e: any) => {
    dispatchEditableField({
      type: EditableFieldActionType.UpdateField,
      field: 'label',
      payload: e.target.value,
    });
  };

  const saveAttribute = useCallback(() => {
    dispatchFieldAction({ type: MouseActionType.Blur, field: '' });
    if (value !== fieldState.value || fieldState.label !== attributeLabel) {
      onUpdateAttribute(attributeId, fieldState.label, fieldState.value);
    }
  }, [
    dispatchFieldAction,
    value,
    fieldState.value,
    fieldState.label,
    attributeId,
    attributeLabel,
    onUpdateAttribute,
  ]);

  const handleMouseOver = (field: string) => {
    dispatchFieldAction({ type: MouseActionType.MouseOver, field });
  };

  const handleMouseLeave = (field: string) => {
    dispatchFieldAction({ type: MouseActionType.MouseLeave, field });
  };

  const handleEdit = (field: string) => {
    dispatchFieldAction({ type: MouseActionType.Edit, field });
  };

  useClickOutside(editBoxRef, saveAttribute);

  const areControllersVisible = isHoveringField || fieldActionState.isEditing;

  return (
    <EditContainer
      isHovering={isHoveringField}
      onMouseOver={handleMouseOverField}
      onMouseLeave={handleMouseLeaveField}
      isEditing={fieldActionState.isEditing}
      data-testid="editable-container"
    >
      <LeftIconWrapper isVisible={areControllersVisible || isDragging}>
        <MovableIconWrapper
          {...dragHandleProps}
          isEditing={fieldActionState.isEditing}
        >
          <MovableIcon />
        </MovableIconWrapper>
      </LeftIconWrapper>
      <EditContentBox ref={editBoxRef}>
        <InputField
          fontWeight={300}
          value={fieldState.label}
          onChange={handleLabelChange}
          onMouseOver={() => handleMouseOver('label')}
          onMouseLeave={() => handleMouseLeave('label')}
          maxLength={titleMaxLength}
          onClick={() => handleEdit('label')}
          onFocus={() => handleEdit('label')}
          onBlur={saveAttribute}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              saveAttribute();
              valueInputRef?.current?.focus();
            }
          }}
          uppercase={true}
          data-testid="attribute-title"
        />
        <UnderlineInputField
          active={
            fieldActionState.label === EditableState.Editing ||
            fieldActionState.label === EditableState.Hovered
          }
        />
        {fieldActionState.label === EditableState.Editing && (
          <CharacterLimitation>
            {fieldState.label.length}/{titleMaxLength}
          </CharacterLimitation>
        )}
        <InputField
          ref={valueInputRef}
          fontWeight={300}
          value={fieldState.value}
          onChange={handleValueChange}
          onMouseOver={() => handleMouseOver('value')}
          onMouseLeave={() => handleMouseLeave('value')}
          maxLength={valueMaxLength}
          onFocus={() => handleEdit('value')}
          onBlur={saveAttribute}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              saveAttribute();
              valueInputRef?.current?.blur();
            }
          }}
          onClick={() => handleEdit('value')}
          uppercase={false}
          data-testid="attribute-value"
        />
        <UnderlineInputField
          active={
            fieldActionState.value === EditableState.Editing ||
            fieldActionState.value === EditableState.Hovered
          }
        />
        {fieldActionState.value === EditableState.Editing && (
          <CharacterLimitation>
            {fieldState.value.length}/{valueMaxLength}
          </CharacterLimitation>
        )}
        {(fieldState.label.length > titleMaxLength ||
          fieldState.value.length > valueMaxLength) && (
          <WarningMessage>{t('attribute.maxLengthWarning')}</WarningMessage>
        )}
      </EditContentBox>
      <RemoveIconWrapper isVisible={areControllersVisible}>
        <RemoveIcon
          onClick={handleConfirmRemove}
          data-testid="attribute-remove"
        />
      </RemoveIconWrapper>
      <Popover shown={isConfirmationVisible} onClose={handleCancel}>
        <TextRegular1>{t('general.confirmDeleteField')}</TextRegular1>
        <RemoveButtonWrapper>
          <Button variant="text" onClick={handleCancel} data-testid="cancel">
            {t('general.cancel')}
          </Button>
          <Button onClick={handleRemove} data-testid="delete">
            {t('general.delete')}
          </Button>
        </RemoveButtonWrapper>
      </Popover>
    </EditContainer>
  );
};

export default EditableAttributeField;

const EditContainer = styled.div<{ isHovering: boolean; isEditing: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 2.3% 0;
  background-color: ${props =>
    props.isHovering
      ? '#F4F4F5'
      : props.isEditing
      ? 'rgba(200, 202, 205, 0.2)'
      : 'inherit'};
  &::after {
    content: ' ';
    display: block;
    height: 0;
    clear: both;
  }
`;

const LeftIconWrapper = styled.div<{ isVisible: boolean }>`
  width: 11%;
  cursor: move;
  align-self: center;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const EditContentBox = styled.div`
  width: 71%;
`;

const RemoveIconWrapper = styled.div<{ isVisible: boolean }>`
  width: 18%;
  float: right;
  cursor: pointer;
  align-self: center;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  svg {
    display: block;
    margin: auto;
    width: 0.75em;
    height: 0.75em;
  }
`;

const InputField = styled(NoOutlineInputField)<{
  fontWeight: number;
  uppercase: boolean;
}>`
  width: 100%;
  font-weight: ${props => props.fontWeight};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1em;
  background: inherit;
  cursor: pointer;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`;

const MovableIconWrapper = styled.div<{ isEditing: boolean }>`
  svg {
    display: block;
    margin: auto;
    circle {
      fill: ${props => (props.isEditing ? '#C8CACD' : '#F47457')};
    }
  }
`;

const RemoveButtonWrapper = styled.div`
  padding-top: 15px;
`;

const CharacterLimitation = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  padding-top: 0.18rem;
  text-align: right;
  font-size: 0.8em;
`;

const WarningMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  @media print {
    display: none;
  }
`;
