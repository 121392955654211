import { createSlice } from '@reduxjs/toolkit';

export type Modals = {
  warningAppExitOpen: boolean;
};

const modalsInitState: Modals = {
  warningAppExitOpen: false,
};

export const modalsSlice = createSlice({
  initialState: modalsInitState,
  name: 'modals',
  reducers: {
    warningAppExitClose(state) {
      state.warningAppExitOpen = false;
    },
    warningAppExitOpen(state) {
      state.warningAppExitOpen = true;
    },
  },
});

export const { warningAppExitClose, warningAppExitOpen } = modalsSlice.actions;

export default modalsSlice.reducer;
