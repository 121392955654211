import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { hamburgerImage } from 'assets';

interface ResizableSideBarProps {
  children: React.ReactNode;
  direction: 'left' | 'right';
  isOpen: boolean;
  isMobileView: boolean;
  setOpen: (isOpen: boolean) => void;
  defaultWidth?: number;
  minWidth?: number;
  maxWidth?: number;
  buttonLabel?: string;
}

const defaultOptions = {
  width: 440,
  minWidth: 360,
  maxWidth: 600,
};

const ResizableSideBar = ({
  direction,
  defaultWidth,
  minWidth,
  maxWidth,
  children,
  isOpen,
  isMobileView,
  setOpen,
  buttonLabel = '',
}: ResizableSideBarProps) => {
  const sidebarRef: any = useRef(null);
  const isResizing = useRef(false);
  const [sidebarWidth, setSidebarWidth] = useState(
    defaultWidth || defaultOptions.width,
  );

  const startResizing = () => {
    isResizing.current = true;
  };

  const stopResizing = useCallback(() => {
    isResizing.current = false;
    document.body.style.cursor = 'auto';
  }, []);

  const resize = useCallback(
    event => {
      if (!isResizing.current) return;

      document.body.style.cursor = 'ew-resize';
      const clientRect = sidebarRef.current.getBoundingClientRect();
      let width;
      if (direction === 'left') {
        width = event.clientX - clientRect.left;
      } else {
        width = clientRect.right - event.clientX;
      }
      const min = minWidth || defaultOptions.minWidth;
      const max = maxWidth || defaultOptions.maxWidth;

      if (width > max) {
        width = max;
      } else if (width < min) {
        width = min;
      }
      setSidebarWidth(width);
    },
    [direction, maxWidth, minWidth],
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <>
      <SideBarWrapper
        ref={sidebarRef}
        width={sidebarWidth}
        minWidth={minWidth || defaultOptions.minWidth}
        maxWidth={maxWidth || defaultOptions.maxWidth}
        isOpen={isOpen}
        isMobileView={isMobileView}
      >
        {direction === 'right' && (
          <SideBarResizer onMouseDown={startResizing} />
        )}
        <ChildrenWrapper>{children}</ChildrenWrapper>
        {direction === 'left' && <SideBarResizer onMouseDown={startResizing} />}
      </SideBarWrapper>
      <LeftSidebarButton
        isOpen={isOpen}
        isMobileView={isMobileView}
        onClick={() => {
          setOpen(true);
        }}
      >
        <img src={hamburgerImage} alt="" />
        <ButtonLabel>{buttonLabel}</ButtonLabel>
      </LeftSidebarButton>
    </>
  );
};

const SideBarWrapper = styled.div<{
  width: number;
  minWidth: number;
  maxWidth: number;
  isOpen: boolean;
  isMobileView: boolean;
}>`
  position: ${props => (props.isMobileView ? 'fixed' : 'static')};
  display: flex;
  flex-shrink: 0;
  height: 100%;
  ${props =>
    props.isOpen
      ? `
        min-width: ${props.minWidth}px;
        max-width: ${props.maxWidth}px;
        transform: translate(0, 0);
        width: ${props.width}px;
        `
      : `
        transform: translateX(-300%);
        width: 0;
        max-width: 0;
        min-width: 0;
    `}
  z-index: 2;
  overflow: hidden;
  @media print {
    display: none;
  }
`;

const SideBarResizer = styled.div`
  flex-basis: 4px;
  justify-self: flex-end;
  resize: horizontal;
  :hover {
    width: 4px;
    cursor: ew-resize;
    background: #c4d8e3;
  }
`;

const ChildrenWrapper = styled.div`
  width: calc(100% - 4px);
`;

const LeftSidebarButton = styled.button<{
  isOpen: boolean;
  isMobileView: boolean;
}>`
  position: fixed;
  border: none;
  top: ${props => (props.isMobileView ? '80px' : '72px')};
  left: ${props => (props.isMobileView ? '20px' : '60px')};
  width: 120px;
  height: 40px;
  border-radius: 4px;
  background-color: rgb(244, 116, 87);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    padding-right: 5px;
  }
  ${props =>
    props.isOpen
      ? `
      opacity: 0;
    `
      : `
      opacity: 1;
    `}
  z-index: 1;
  @media print {
    display: none;
  }
`;

const ButtonLabel = styled.p`
  line-height: 16px;
`;

export default ResizableSideBar;
