import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import cloneDeep from 'lodash.clonedeep';
import { RootState } from 'store';
import {
  TemplateDescription,
  TemplateSection,
  TemplateTextAttribute,
} from 'types/TemplateDescription';
import {
  SectionIdentifier,
  TemplateIdentifier,
} from 'types/TemplateSectionType';
import { v4 as uuid } from 'uuid';
import {
  updatePageUnit,
  updatePageUnitIfNeeded,
} from '../../utils/updatePageUnit';
import { ATTRIBUTE_TEXT_MAX_LENGTH } from './Template1Description';
import { TemplateSettingsState } from '../settings';

const templateInitState = {
  currentPageIndex: 0,
  generated: false,
  isHoverBody: false,
  fetchingPages: 0,
  fetchedPages: [] as TemplateDescription[],
  // inspired by https://redux.js.org/usage/implementing-undo-history
  past: [] as TemplateDescription[][], // old item will be push in past (UNDO)
  present: [] as TemplateDescription[], // current template items
  future: [] as TemplateDescription[][], // new item will be push in future (REDO)
};

const name = 'page';
export const getImageSectionKey = (templateId: string) =>
  templateId === TemplateIdentifier.Page1
    ? SectionIdentifier.MainImage
    : SectionIdentifier.SelectionImage;

const getDetailsTextAttributesList = (
  present: TemplateDescription[],
  pageIndex: number,
): TemplateTextAttribute[] => {
  const currentPage = present[pageIndex];
  const detailSection = currentPage.sections.find(
    (s: TemplateSection) => s.sectionId === SectionIdentifier.VerticalList,
  );

  return detailSection?.textAttributes || [];
};

const changePresentToPast = (draft: any): TemplateDescription[] => {
  draft.past.push(cloneDeep(draft.present));
  draft.future = [];
  return draft.present;
};

export const pagesSlice = createSlice({
  initialState: templateInitState,
  name,
  reducers: {
    updatePresentWithSettings: (
      state,
      action: PayloadAction<{
        oldTemplateSettings: TemplateSettingsState;
        newTemplateSettings: TemplateSettingsState;
      }>,
    ) => {
      return produce(state, draft => {
        const { fetchedPages, present } = draft;
        const newPresent: TemplateDescription[] = [];

        fetchedPages.forEach(page => {
          const existInPresent = present.find(
            p =>
              p.templateId === page.templateId &&
              p.productId === page.productId,
          );
          const existInTemplateSettings =
            action.payload.newTemplateSettings.formats.includes(
              page.templateId,
            );

          // exist in present and settings (keep)
          if (existInPresent && existInTemplateSettings) {
            newPresent.push(
              updatePageUnitIfNeeded(
                existInPresent,
                action.payload.oldTemplateSettings,
                action.payload.newTemplateSettings,
              ),
            );
          }
          // exist in settings (add)
          else if (existInTemplateSettings) {
            newPresent.push(
              updatePageUnitIfNeeded(
                page,
                action.payload.oldTemplateSettings,
                action.payload.newTemplateSettings,
              ),
            );
          }
        });
        draft.present = [...newPresent];
        draft.currentPageIndex = 0;
      });
    },
    addFetchedPages: (state, action: PayloadAction<TemplateDescription[]>) => {
      return produce(state, draft => {
        const { fetchedPages } = draft;
        draft.fetchedPages = [...fetchedPages, ...action.payload];
      });
    },
    changePage: (state, action: PayloadAction<number>) => {
      return produce(state, draft => {
        draft.currentPageIndex = action.payload;
      });
    },
    updateImageAttributes: (
      state,
      action: PayloadAction<{
        imageIndex: number;
        data: string;
        replaceDefault: boolean;
        moveToEnd?: boolean;
      }>,
    ) => {
      return produce(state, draft => {
        const present = changePresentToPast(draft);
        const matchPage = present[draft.currentPageIndex];
        const imageSection = matchPage.sections.find(
          s => s.sectionId === getImageSectionKey(matchPage.templateId),
        );
        imageSection!.imageAttributes[action.payload.imageIndex].value =
          action.payload.data;

        if (action.payload.replaceDefault) {
          imageSection!.imageAttributes[
            action.payload.imageIndex
          ].defaultValue = action.payload.data;
        }
        if (action.payload.moveToEnd) {
          const fromIndex = action.payload.imageIndex;
          const fromItem =
            imageSection!.imageAttributes[action.payload.imageIndex];
          const toIndex = imageSection!.imageAttributes.length - 1;
          imageSection!.imageAttributes.splice(fromIndex, 1);
          imageSection!.imageAttributes.splice(toIndex, 0, fromItem);
        }
      });
    },
    addPresent: (
      state,
      action: PayloadAction<{
        page: TemplateDescription;
        templateSettings: TemplateSettingsState;
      }>,
    ) => {
      return produce(state, draft => {
        const { present } = draft;
        const existInPresent = present.find(
          template =>
            template.productId === action.payload.page.productId &&
            template.templateId === action.payload.page.templateId,
        );
        const existInTemplateSettings =
          action.payload.templateSettings.formats.includes(
            action.payload.page.templateId,
          );

        // add only if it exists in the settings and has not been added before
        if (existInTemplateSettings && !existInPresent) {
          const newPage = updatePageUnit(
            action.payload.page,
            action.payload.templateSettings.unit,
          );
          draft.present = [...present, newPage];
        }

        if (!state.generated) {
          draft.generated = true;
        }
      });
    },
    removePresent: (state, action: PayloadAction<number>) => {
      return produce(state, draft => {
        const present = changePresentToPast(draft);

        draft.present = present.filter((_, idx) => idx !== action.payload);
        if (
          draft.currentPageIndex !== 0 &&
          draft.currentPageIndex >= action.payload
        ) {
          draft.currentPageIndex = draft.currentPageIndex - 1;
        }
      });
    },
    setFetchingPages: (state, action: PayloadAction<number>) => {
      return produce(state, draft => {
        draft.fetchingPages = action.payload;
      });
    },
    setIsHoverBody: (state, action: PayloadAction<boolean>) => {
      return produce(state, draft => {
        draft.isHoverBody = action.payload;
      });
    },
    updateSectionAttribute: (
      state,
      action: PayloadAction<{
        sectionId: string;
        attributeId: string;
        value: string;
        applyAllPages?: boolean;
        attributeLabel?: string;
      }>,
    ) => {
      return produce(state, draft => {
        const updatePage = (page: TemplateDescription) => {
          const detailSection = page.sections.find(
            s => s.sectionId === action.payload.sectionId,
          );
          const matchAttribute = detailSection?.textAttributes.find(
            t => t.attributeId === action.payload.attributeId,
          );
          if (matchAttribute) {
            matchAttribute.value = action.payload.value;
            if (action.payload.attributeLabel) {
              matchAttribute.attributeLabel = action.payload.attributeLabel;
            }
          }
        };

        const present = changePresentToPast(draft);
        const currentPage = present[draft.currentPageIndex];

        if (action.payload.applyAllPages) {
          const productId = currentPage.productId;
          present.forEach(page => {
            if (page.productId === productId) updatePage(page);
          });
        } else {
          updatePage(currentPage);
        }
      });
    },
    updateTextAttributeIndex: (
      state,
      action: PayloadAction<{ fromIndex: number; toIndex: number }>,
    ) => {
      const { fromIndex, toIndex } = action.payload;
      return produce(state, draft => {
        const present = changePresentToPast(draft);
        const textAttributes = getDetailsTextAttributesList(
          present,
          draft.currentPageIndex,
        );
        if (textAttributes) {
          const fromItem = textAttributes[fromIndex];
          textAttributes.splice(fromIndex, 1);
          textAttributes.splice(toIndex, 0, fromItem);
        }
      });
    },
    upsertTextAttribute: (
      state,
      action: PayloadAction<{
        attributeLabel: string;
        value: string;
      }>,
    ) => {
      return produce(state, draft => {
        const present = changePresentToPast(draft);
        const textAttributes = getDetailsTextAttributesList(
          present,
          draft.currentPageIndex,
        );
        if (textAttributes) {
          const { value, attributeLabel } = action.payload;
          textAttributes.push({
            attributeId: uuid(),
            attributeLabel,
            enabled: true,
            isLabelEditable: true,
            isMultiLine: false,
            order: textAttributes.length + 1,
            textMaxLength: ATTRIBUTE_TEXT_MAX_LENGTH,
            dimension: null,
            value,
          });
        }
      });
    },
    removeTextAttribute: (
      state,
      action: PayloadAction<TemplateTextAttribute>,
    ) => {
      return produce(state, draft => {
        const present = changePresentToPast(draft);

        const currentPage = present[draft.currentPageIndex];
        const detailSection = currentPage.sections.find(
          (s: TemplateSection) => s.sectionId === 'attributesVerticalList',
        );
        if (detailSection?.textAttributes) {
          detailSection.textAttributes = detailSection?.textAttributes.filter(
            (ta: TemplateTextAttribute) =>
              ta.attributeId !== action.payload.attributeId,
          );
        }
      });
    },
    undo: state => {
      return produce(state, draft => {
        const { past, present, future } = draft;
        if (!past || !past.length) {
          return;
        }
        draft.present = past[past.length - 1];
        draft.past = past.slice(0, past.length - 1); // remove last item and return a copy
        draft.future = [present, ...future];
      });
    },
    redo: state => {
      return produce(state, draft => {
        const { past, future } = draft;
        if (!future || !future.length) {
          return;
        }
        draft.past = [...past, draft.present];
        draft.present = future.shift()!;
        draft.future = future;
      });
    },
    resetAllChanges: state => {
      return produce(state, draft => {
        draft.present = draft.past[0] || draft.present;
        draft.past = [];
        draft.future = [];
      });
    },
  },
});

export const pageSliceReducer = pagesSlice.reducer;
export const {
  updatePresentWithSettings,
  updateImageAttributes,
  changePage,
  addFetchedPages,
  addPresent,
  removePresent,
  setFetchingPages,
  updateSectionAttribute,
  updateTextAttributeIndex,
  upsertTextAttribute,
  removeTextAttribute,
  undo,
  redo,
  resetAllChanges,
  setIsHoverBody,
} = pagesSlice.actions;

// selectors
export const getPageState = (state: RootState) => state[name];

export const getCurrentPage = (state: RootState) => {
  const pagesState = getPageState(state);
  return pagesState.present[pagesState.currentPageIndex] as TemplateDescription;
};

export const getImageSection = (state: RootState) => {
  const page = getCurrentPage(state);
  const sectionId = getImageSectionKey(page?.templateId);
  return page?.sections.find(s => s.sectionId === sectionId);
};

export const getImageAttributes = (state: RootState) => {
  const section = getImageSection(state);
  return section?.imageAttributes;
};

export const getVerticalListAttributes = (state: RootState) => {
  const page = getCurrentPage(state);
  return page?.sections.find(
    s => s.sectionId === SectionIdentifier.VerticalList,
  );
};

export const getPageUndoableStatus = (state: RootState) => {
  const { past, future } = state.page;
  return {
    canUndo: past.length > 0,
    canRedo: future.length > 0,
  };
};

export default pagesSlice.reducer;
