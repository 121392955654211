import styled from 'styled-components';

const H2Alternative = styled.span`
  display: block;
  font-weight: 900;
  font-size: 18px;
  line-height: 140%;
  color: #3b506b;
`;

export default H2Alternative;
