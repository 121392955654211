import { useEffect, useReducer, useState } from 'react';
import { EditableFieldActionType } from 'types/EditableFieldActionType';
import { EditableState } from 'types/EditableState';
import { MouseActionType } from 'types/MouseActionType';

interface IFieldAction {
  label: string;
  value: string;
}
const initialActionState = {
  label: '',
  value: '',
};

const actionReducer = (
  state: IFieldAction,
  action: { type: string; field: string },
) => {
  const prev = (state as any)[action.field];
  switch (action.type) {
    case MouseActionType.MouseOver:
      if (prev === EditableState.Editing) {
        return state;
      }
      return {
        ...state,
        [action.field]: EditableState.Hovered,
      };
    case MouseActionType.MouseLeave:
      if (prev === EditableState.Editing) {
        return state;
      }
      return {
        ...state,
        [action.field]: 'mouse_leave',
      };
    case MouseActionType.Edit:
      return {
        ...initialActionState,
        [action.field]: EditableState.Editing,
      };
    case MouseActionType.Blur:
      return initialActionState;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

interface IEditableField {
  label: string;
  value: string;
}

const reducer = (
  state: IEditableField,
  action: {
    type: string;
    field?: 'label' | 'value';
    payload: string | any;
  },
) => {
  switch (action.type) {
    case EditableFieldActionType.UpdateField:
      return {
        ...state,
        [action.field!]: action.payload,
      };
    case EditableFieldActionType.UpdateMultipleField:
      return action.payload;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const useEditableFieldAction = () => {
  const [fieldActionState, dispatchFieldAction] = useReducer(
    actionReducer,
    initialActionState,
  );
  return {
    dispatchFieldAction,
    fieldActionState: {
      ...fieldActionState,
      isEditing:
        fieldActionState.label === EditableState.Editing ||
        fieldActionState.value === EditableState.Editing,
    },
  };
};

export const useEditableField = (initialState: IEditableField) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      type: EditableFieldActionType.UpdateMultipleField,
      payload: {
        label: initialState.label,
        value: initialState.value,
      },
    });
  }, [initialState.value, initialState.label, dispatch]);

  return {
    fieldState: state,
    dispatchEditableField: dispatch,
  };
};

export const useHoveringField = () => {
  const [isHovering, setIsHovering] = useState(false);

  return {
    isHovering,
    handleMouseOverField: () => setIsHovering(true),
    handleMouseLeaveField: () => setIsHovering(false),
  };
};
